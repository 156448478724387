import {Component, OnInit} from '@angular/core';
import {MissionDto} from "@server-models";
import {CommonModule} from "@angular/common";
import {IonicModule} from "@ionic/angular";
import {select, Store} from "@ngrx/store";
import {TechInventorySelectors} from "@tech/pages/inventory/store/tech-inventory.selector-type";
import {Observable, of} from "rxjs";
import {
  TechInventoryMissionDetailViewCustomValuesReadComponent
} from "@tech/pages/inventory/components/tech-inventory-mission-detail/pages/mission-detail-view/components/custom-values-read/custom-values-read.component";
import {AttachmentsComponent} from "@shared/components/attachments/attachments.component";
import {TranslateModule} from "@ngx-translate/core";
import {
  TechInventoryMissionDetailViewResumeComponent
} from "@tech/pages/inventory/components/tech-inventory-mission-detail/pages/mission-detail-view/components/resume/resume.component";
import {
  TechInventoryMissionDetailViewDescriptionComponent
} from "@tech/pages/inventory/components/tech-inventory-mission-detail/pages/mission-detail-view/components/description/description.component";
import {BaseIssuesActions} from "@shared/components/issues/store/base-issues.action-type";
import {CacheService} from "@shared/services/cache/cache.service";
import {TechIssuesSelectors} from "@tech/pages/issues/store/tech-issues.selector-type";
import {HeaderDesignEnum} from "@shared/components/custom-property/read/enums/header-design.enum";
import {
  CustomPropertySetsReadComponent
} from "@shared/components/custom-property/read/custom-property-sets-read.component";
import {
  CustomPropertyStructureFormatted
} from "@shared/components/custom-property/interfaces/custom-property-structure-formatted.interface";

@Component({
  selector: 'app-tech-inventory-issue-detail-page',
  templateUrl: './issue-detail.page.html',
  styleUrls: ['./issue-detail.page.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule, TechInventoryMissionDetailViewResumeComponent, TechInventoryMissionDetailViewDescriptionComponent, TechInventoryMissionDetailViewCustomValuesReadComponent, AttachmentsComponent, TranslateModule, CustomPropertySetsReadComponent],

})
export class TechInventoryIssueDetailPage implements OnInit {
  protected readonly HeaderDesignEnum = HeaderDesignEnum;

  missionDetail$: Observable<MissionDto | undefined>;
  inventoryIsLoading$: Observable<boolean>;
  issueFormattedData$: Observable<CustomPropertyStructureFormatted>;
  issueFormattedDataIsLoading$: Observable<boolean>;

  currentMissionDetail: MissionDto | undefined;

  constructor(
    private _store: Store,
    private _cacheService: CacheService
  ) {
    this.missionDetail$ = of();
    this.inventoryIsLoading$ = of();
    this.issueFormattedData$ = of();
    this.issueFormattedDataIsLoading$ = of();
    this.currentMissionDetail = undefined;
  }

  ngOnInit() {
    this.missionDetail$ = this._store.pipe(select(TechInventorySelectors.selectTechInventoryPageMissionDetail));
    this.missionDetail$.subscribe((missionData) => {
      if (missionData) {
        const primaryIssue = missionData?.origin?.primaryIssue;
        if (primaryIssue?.issueId) {
          if (missionData.missionId) {
            this._store.dispatch(BaseIssuesActions.navigateToDetail({
              issueId: primaryIssue.issueId!,
              issueType: primaryIssue.issueType!,
              cacheGuid: this._cacheService.generateGuid(),
              mustNavigate: false
            }));
          }
        }
      }
    });
    this.issueFormattedData$ = this._store.pipe(select(TechIssuesSelectors.selectDetailDataFormatted));
    this.issueFormattedDataIsLoading$ = this._store.pipe(select(TechIssuesSelectors.selectIsLoading));
  }
}
