import { Component, OnInit } from '@angular/core';
import { ModalControllerService } from '@shared/services/modal-controller.service';
import { IonicModule } from '@ionic/angular';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { select, Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { ArticleListDto } from '@server-models';
import { TechInventoryActions } from '@tech/pages/inventory/store/tech-iventory.actions-type';
import { TechInventorySelectors } from '@tech/pages/inventory/store/tech-inventory.selector-type';
import { Observable } from 'rxjs';
import { startWith } from 'rxjs/operators';
import { BaseSearchBarComponent } from '@shared/components/search-bar/components/base-search-bar.component';

@Component({
  selector: 'app-report-article-selection-modal',
  templateUrl: './report-article-selection-modal.component.html',
  styleUrls: ['./report-article-selection-modal.component.scss'],
  imports: [CommonModule, IonicModule, FormsModule, TranslateModule, BaseSearchBarComponent],
  standalone: true,
})
export class ReportArticleSelectionModalComponent implements OnInit {
  isLoading$!: Observable<boolean>;
  articles$!: Observable<ArticleListDto[]>;
  articleSelectFn: (article: ArticleListDto) => void = () => undefined;

  constructor(
    private _modalCtrlService: ModalControllerService,
    private _store: Store
  ) {}

  ngOnInit() {
    this.isLoading$ = this._store.pipe(select(TechInventorySelectors.selectTechInventoryPageMissionReportArticlesToAddIsLoading));
    this._store.dispatch(TechInventoryActions.getMissionReportArticleList({}));
    this.articles$ = this._store.select(
      TechInventorySelectors.selectTechInventoryPageMissionReportArticlesToAddData
    );
  }

  onSearch(searchTerm: string):void {
    this._store.dispatch(
      TechInventoryActions.getMissionReportArticleList({ searchTerm })
    );
  }

  onSelectArticle(article: ArticleListDto): void {
    this.articleSelectFn(article);
    this.onClose();
  }

  onClose(): void {
    this._modalCtrlService.closeModal();
  }

  getArticleName(article: ArticleListDto): string {
    return article.number
      ? `${article.number} ${article.name}`
      : article.name ?? '';
  }
}
