import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';

import {
  BehaviorSubject,
  filter,
  firstValueFrom,
  Observable,
  of,
  pairwise,
  Subject,
  Subscription
} from 'rxjs';
import { cloneDeep, isEqual } from 'lodash';

import { TranslateModule, TranslateService } from '@ngx-translate/core';

import { FormsModule } from '@angular/forms';
import { IonicModule, IonModal } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import {
  TechInventoryMissionByDistanceApiService
} from '@tech/pages/inventory/services/mission-by-distance-api.service';
import {
  FilterDto,
  MissionDistanceListDto,
  PointListDto,
  ResourceInMissionDto
} from '@server-models';
import { Geolocation, Position } from '@capacitor/geolocation';
import { catchError, map, startWith, tap } from 'rxjs/operators';
import { OrgaResponse } from '@shared/interfaces/orga-response.interface';
import { GoogleMapsModule } from '@angular/google-maps';
import { ToastControllerService } from '@shared/services/toast-controller.service';
import { Router } from '@angular/router';

export interface NearbyMissionMapDto {
  origin?: google.maps.LatLngLiteral;
  nearby: NearbyMissionsDto[];
}

export interface NearbyMissionsDto {
  point: google.maps.LatLngLiteral;
  location: {
    name: string;
    address: string;
  };
  missions?: MissionDistanceListDto[];
}

@Component({
  selector: 'app-tech-inventory-mission-map',
  templateUrl: './tech-inventory-mission-map.component.html',
  styleUrls: ['./tech-inventory-mission-map.component.scss'],
  standalone: true,
  imports: [
    IonicModule,
    CommonModule,
    TranslateModule,
    FormsModule,
    GoogleMapsModule
  ]
})
export class TechInventoryMissionMapComponent implements OnInit, OnDestroy {
  @ViewChild('detailModal') public detailModal!: IonModal;

  @Input() public missionFilter$?: Observable<FilterDto[]>;
  public missionFilter: FilterDto[] = [];

  public loading$ = new Subject<boolean>();

  public nearbyMissions$: Observable<NearbyMissionMapDto> = of({} as NearbyMissionMapDto);
  public missions: MissionDistanceListDto[] = [];
  public currentNearbyMissionsForModal$: BehaviorSubject<NearbyMissionsDto | undefined> = new BehaviorSubject<NearbyMissionsDto | undefined>(undefined);

  public map!: google.maps.Map;
  public options: google.maps.MapOptions;
  public detailZoom = 9;
  public overviewZoom = 6;

  public currentOrigin: google.maps.LatLngLiteral | undefined;
  public defaultLocation: google.maps.LatLngLiteral = {
    lat: 50.00000,
    lng: 10.00000
  };
  public userPositionIcon: string = 'assets/icon/google-gps/gps-icon.png';

  public subscriptions: (Subscription | undefined)[] = [];

  constructor(
    private _missionByDistanceService: TechInventoryMissionByDistanceApiService,
    private _router: Router,
    private _translate: TranslateService,
    private _toastService: ToastControllerService
  ) {
    // Map options
    this.options = {
      streetViewControl: false,
      disableDefaultUI: true,
      center: this.defaultLocation,
      // Map style
      // Generate a style json here:
      // https://mapstyle.withgoogle.com/
      styles: [
        {
          featureType: 'administrative.land_parcel',
          elementType: 'labels',
          stylers: [
            {
              visibility: 'off'
            }
          ]
        },
        {
          featureType: 'poi',
          elementType: 'labels',
          stylers: [
            {
              visibility: 'off'
            }
          ]
        }
      ]
    };
  }

  public ngOnInit(): void {
    this.loading$.next(true);
    this.initFilterOptions();
  }

  public ngOnDestroy(): void {
    for (const subscription of this.subscriptions) {
      subscription?.unsubscribe();
    }
  }

  public initFilterOptions(): void {
    // Action when filters changed (distinct)
    const filterSubscription = this.missionFilter$
      ?.pipe(
        startWith(null),
        pairwise(),
        filter(([prev, curr]) => !isEqual(prev, curr))
      )
      ?.subscribe(([_, currenFilter]) => {
        this.missionFilter = currenFilter ?? [];
        if (this.currentOrigin)
          this.findMissions(
            this.currentOrigin,
            this.missionFilter
          );
      });

    this.subscriptions.push(filterSubscription);
  }

  public initMap(map: google.maps.Map): void {
    this.map = map;
    this.initCenter();
    this.initUserPosition();
  }

  public initCenter(): void {
    this.map.setZoom(this.overviewZoom);
    this.map.setCenter(this.defaultLocation);
    this.loading$.next(false);
  }

  public initUserPosition(suppressToast: boolean = false): void {
    this.loading$.next(true);
    Geolocation.getCurrentPosition().then(
      // On gps position found
      (userPosition: Position) =>
        this.findMissions({
          lat: userPosition.coords.latitude,
          lng: userPosition.coords.longitude
        }),
      // On gps position error
      (reason) => {
        if (!suppressToast) {
          this._toastService.observableToast({
            duration: 6000,
            message: this._translate.instant('COMPONENTS.MISSIONS.MAP.TOASTS.NO_GPS')
          });
        }
        this.loading$.next(false);
        // Repeat until user grands access to user location
        setTimeout(() => this.initUserPosition(true), 3000);
      }
    );
  }

  public centerMap(location: google.maps.LatLngLiteral): void {
    this.map.setCenter(location);
  }

  public findMissions(
    origin: google.maps.LatLngLiteral,
    filter?: FilterDto[],
    page: number = 0,
    appendItems?: boolean
  ): void {
    // Init filter and show loading spinner
    const missionFilter: FilterDto[] = filter ?? this.missionFilter ?? [];
    this.loading$.next(true);

    // Reset map
    this.resetNearbyMissions(origin);
    this.currentOrigin = origin;

    // Load nearby missions
    this.nearbyMissions$ = this._missionByDistanceService
      .getMissionsByDistance(origin, missionFilter)
      .pipe(
        catchError((error) => {
          this._toastService.observableToast({
            message: this._translate.instant('COMPONENTS.MISSIONS.MAP.TOASTS.NO_INTERNET')
          });
          return of({ items: [] } as any);
        }),
        map(foundMissions => {
          // A way to use paging, but currently it is set fixed to page size 100
          if (appendItems) {
            this.missions = [...this.missions, ...foundMissions.items];
          } else {
            this.missions = foundMissions.items;
          }
          // Show toast if there are no missions
          if (this.missions.length === 0) {
            this._toastService.observableToast({
              message: this._translate.instant('COMPONENTS.MISSIONS.MAP.TOASTS.NO_NEARBY_MISSIONS')
            });
          }
          // Map to local dto
          return this._generateNearbyMissions(origin, this.missions);
        }),
        // Stop loading, center and zoom map
        tap(()=> {
          this.loading$.next(false);
          this.centerMap(origin);
          this.map.setZoom(this.detailZoom);
        })
      )
  }

  public resetNearbyMissions(origin?: google.maps.LatLngLiteral): void {
    // initialize
    this.nearbyMissions$ = of({
      origin,
      nearby: []
    });
    this.currentOrigin = origin;
  }

  public onMarkerClick(nearby: NearbyMissionsDto): void {
    this.currentNearbyMissionsForModal$.next(nearby);
    setTimeout(() => this.detailModal.present());
  }

  public openMissionDetail(mission: MissionDistanceListDto): void {
    this._router.navigate(['tech/logged/inventory/mission', mission.missionId, 'view'])
      .then(() => this.detailModal.dismiss());
  }

  public getResourceInfo(resource: ResourceInMissionDto): string {
    return `${
      resource.inventoryNumber ? resource.inventoryNumber.toString() + ' ' : ''
    }${resource.name}`;
  }

  private _generateNearbyMissions(
    origin: PointListDto,
    missions: MissionDistanceListDto[]
  ): NearbyMissionMapDto {
    const nearbyMissionMap = {nearby: []} as NearbyMissionMapDto;

    // Clone missions
    missions = cloneDeep(missions);

    // Place origin
    nearbyMissionMap.origin = origin as google.maps.LatLngLiteral;

    // Generate local mission map dto
    while (missions.length > 0) {
      const nearbyMissions: NearbyMissionsDto = {
        point: missions[0]?.shippingAddress?.point as google.maps.LatLngLiteral,
        location: this._getCurrentMarkerLocation(missions),
        missions: []
      };
      for (let j = 0; j < missions.length; j++) {
        if (
          isEqual(missions[j]?.shippingAddress?.point, nearbyMissions.point)
        ) {
          nearbyMissions?.missions?.push(missions[j]);
          missions.splice(j, 1);
          j--;
        }
      }
      nearbyMissionMap.nearby.push(nearbyMissions);
    }
    return nearbyMissionMap;
  }

  private _getCurrentMarkerLocation(missions: MissionDistanceListDto[]): { name: string; address: string } {
    // Address
    const address = missions[0]?.shippingAddress;
    // Location if available
    const location = missions.find((mission) => !!mission.location?.name)?.location;

    return {
      name: `${location?.name ? location?.name : ''}`,
      address: `${address?.street ?? ''} ${address?.houseNumber ?? ''}, ${
        address?.zipcode ?? ''
      } ${address?.city ?? ''}`
    };
  }
}
