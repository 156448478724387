import {createReducer, on} from "@ngrx/store";
import {environment} from "@env-tech/environment.local";
import {
  TechInventoryPageMenuTreeItemListMainItem,
  TechInventoryState
} from "@tech/pages/inventory/store/tech-invetory.state";
import {TechInventoryActions} from "@tech/pages/inventory/store/tech-iventory.actions-type";
import {LocationListDto, ResourceModelListDto, StereotypeListDto} from "@server-models";

export const initialInventoryState: TechInventoryState = {
  page: {
    mission: {
      items: [],
      paging: {
        totalItems: 0,
        pageNumber: 0,
        pageSize: environment?.apiUrl?.pageDefaultSize || 1,
        totalPages: 1
      },
      stereotypes: [],
      isLoading: true,
      isLoadingId: NaN,
      detail: undefined,
      changeState: {
        current: null,
        loading: false
      },
      report: {
        isLoading: false,
        previewCost: {},
        data: undefined,
        stereotypeTypes: {
          data: undefined,
          paging: {
            totalItems: 0,
            pageNumber: 0,
            pageSize: environment?.apiUrl?.pageDefaultSize || 1,
            totalPages: 1
          },
          allStereotypes: undefined,
          formattedData: undefined,
          isLoading: false
        },
        articlesToAdd: {
          isLoading: false,
          data: []
        },
        isSubmitLoading: false,
        needModify: false
      }
    },
    menuTree: {
      list: {
        paging: {
          totalItems: 0,
          pageNumber: 0,
          pageSize: environment?.apiUrl?.pageDefaultSize || 1,
          totalPages: 1
        },
        isLoading: true,
        isLoadingId: NaN,
        items: [
          {
            mainItem: {},
            mainItemPaging: {
              totalItems: 0,
              pageNumber: 0,
              pageSize: environment?.apiUrl?.pageDefaultSize || 1,
              totalPages: 1
            },
            mainItemIsLoading: true,
            resourceItems: [],
            selectedResource: {}
          }
        ],
        selectedMainItem: {}
      }
    },

  },
};

export const techInventoryStore = createReducer(
  initialInventoryState,
  on(TechInventoryActions.postMissionPagePaginated, (state) => ({
    ...state,
    page: {
      ...state.page,
      mission: {
        ...state.page.mission,
        isLoading: true
      }
    }
  })),
  on(TechInventoryActions.postMissionPagePaginatedCancel, (state) => ({
    ...state,
    page: {
      ...state.page,
      mission: {
        ...state.page.mission,
        isLoading: false
      }
    }
  })),
  on(TechInventoryActions.postMissionPagePaginatedSuccess, (state, {data}) => ({
    ...state,
    page: {
      ...state.page,
      mission: {
        ...state.page.mission,
        // Important! include the current state.items and the data.items to create a new array with the
        // existing ones and the new one that is requested
        items: [...state.page.mission.items, ...data.items],
        paging: data.paging,
        isLoading: true
      }
    }
  })),
  on(TechInventoryActions.postMissionPagePaginatedDone, (state, {data}) => ({
    ...state,
    page: {
      ...state.page,
      mission: {
        ...state.page.mission,
        stereotypes: data.items,
        isLoading: false
      }
    }
  })),
  on(TechInventoryActions.postMissionPagePaginatedFail, (state) => ({
    ...state,
    page: {
      ...state.page,
      mission: {
        ...state.page.mission,
        isLoading: false
      }
    }
  })),
  on(TechInventoryActions.postMissionPagePaginatedRefresh, (state, {data}) => ({
    ...state,
    page: {
      ...state.page,
      mission: {
        ...state.page.mission,
        items: [...data.items],
        paging: data.paging,
        isLoading: false
      }
    }
  })),
  on(TechInventoryActions.navigateToMissionDetail, (state) => ({
    ...state,
    page: {
      ...state.page,
      mission: {
        ...state.page.mission,
        isLoading: true
      }
    }
  })),
  // detail
  on(TechInventoryActions.getMissionDetailById, (state) => ({
    ...state,
    page: {
      ...state.page,
      mission: {
        ...state.page.mission,
        isLoading: true
      }
    }
  })),
  on(TechInventoryActions.getMissionDetailByIdSuccess, (state, {data}) => ({
    ...state,
    page: {
      ...state.page,
      mission: {
        ...state.page.mission,
        detail: data,
        isLoading: true
      }
    }
  })),
  on(TechInventoryActions.getMissionDetailByIdFail, (state) => ({
    ...state,
    page: {
      ...state.page,
      mission: {
        ...state.page.mission,
        isLoading: false
      }
    }
  })),
  on(TechInventoryActions.getMissionDetailByIdDone, (state, {customPropertySets}) => ({
    ...state,
    page: {
      ...state.page,
      mission: {
        ...state.page.mission,
        customPropertySetFormattedList: customPropertySets,
        isLoading: false
      }
    }
  })),
  on(TechInventoryActions.getMissionDetailStereotypeById, (state) => ({
    ...state,
    page: {
      ...state.page,
      mission: {
        ...state.page.mission,
        isLoading: true
      }
    }
  })),
  on(TechInventoryActions.getMissionDetailStereotypeByIdSuccess, (state, {stereotype}) => ({
    ...state,
    page: {
      ...state.page,
      mission: {
        ...state.page.mission,
        selectedStereotype: stereotype,
        isLoading: true
      }
    }
  })),
  on(TechInventoryActions.setMissionState, (state) => ({
    ...state,
    page: {
      ...state.page,
      mission: {
        ...state.page.mission,
        changeState: {
          loading: true
        }
      }
    }
  })),
  on(TechInventoryActions.setMissionStateSuccess, (state, {missionState}) => ({
    ...state,
    page: {
      ...state.page,
      mission: {
        ...state.page.mission,
        changeState: {
          current: missionState,
          loading: false
        }
      }
    }
  })),
  on(TechInventoryActions.setMissionStateFail, (state) => ({
    ...state,
    page: {
      ...state.page,
      mission: {
        ...state.page.mission,
        changeState: {
          loading: false
        }
      }
    }
  })),
  on(TechInventoryActions.navigateToInventory, (state) => ({
    ...state,
    page: {
      ...state.page,
      mission: {
        ...state.page.mission,
        isLoading: true
      }
    }
  })),
  // report
  on(TechInventoryActions.getMissionReportById, (state) => ({
    ...state,
    page: {
      ...state.page,
      mission: {
        ...state.page.mission,
        report: {
          ...state.page.mission.report,
          needModify: false,
          isLoading: true
        }
      }
    }
  })),
  on(TechInventoryActions.getMissionReportByIdSuccess, (state, {data}) => ({
    ...state,
    page: {
      ...state.page,
      mission: {
        ...state.page.mission,
        report: {
          ...state.page.mission.report,
          data,
          isLoading: false
        },
        isLoading: false
      }
    }
  })),
  on(TechInventoryActions.getMissionReportByIdFail, (state) => ({
    ...state,
    page: {
      ...state.page,
      mission: {
        ...state.page.mission,
        report: {
          ...state.page.mission.report,
          isLoading: false
        },
        isLoading: false
      }
    }
  })),
  on(TechInventoryActions.getMissionReportPreviewById, (state) => ({
    ...state,
    page: {
      ...state.page,
      mission: {
        ...state.page.mission,
        report: {
          ...state.page.mission.report,
          needModify: false
        },
        isLoading: true
      }
    }
  })),
  on(TechInventoryActions.getMissionReportPreviewByIdReset, (state) => ({
    ...state,
    page: {
      ...state.page,
      mission: {
        ...state.page.mission,
        detail: undefined,
        report: {
          ...state.page.mission.report,
          data: undefined,
          needModify: false
        },
        isLoading: false
      }
    }
  })),
  on(TechInventoryActions.getMissionReportPreviewByIdSuccess, (state, {data}) => ({
    ...state,
    page: {
      ...state.page,
      mission: {
        ...state.page.mission,
        report: {
          ...state.page.mission.report,
          previewCost: data,
          needModify: true
        },
        isLoading: false
      }
    }
  })),
  on(TechInventoryActions.getMissionReportPreviewByIdFail, (state) => ({
    ...state,
    page: {
      ...state.page,
      mission: {
        ...state.page.mission,
        report: {
          ...state.page.mission.report,
          needModify: true
        },
        isLoading: false
      }
    }
  })),
  on(TechInventoryActions.getMissionReportArticleList, (state) => ({
    ...state,
    page: {
      ...state.page,
      mission: {
        ...state.page.mission,
        report: {
          ...state.page.mission.report,
          articlesToAdd: {
            ...state.page.mission.report.articlesToAdd,
            isLoading: true,
          }
        }
      }
    }
  })),
  on(TechInventoryActions.getMissionReportArticleListSuccess, (state, {data}) => ({
    ...state,
    page: {
      ...state.page,
      mission: {
        ...state.page.mission,
        report: {
          ...state.page.mission.report,
          articlesToAdd: {
            data,
            isLoading: false,
          }
        }
      }
    }
  })),
  on(TechInventoryActions.getMissionReportArticleListFail, (state,) => ({
    ...state,
    page: {
      ...state.page,
      mission: {
        ...state.page.mission,
        report: {
          ...state.page.mission.report,
          articlesToAdd: {
            ...state.page.mission.report.articlesToAdd,
            isLoading: false,
          }
        }
      }
    }
  })),
  on(TechInventoryActions.submitMissionReport, (state, {formattedData}) => ({
    ...state,
    page: {
      ...state.page,
      mission: {
        ...state.page.mission,
        report: {
          ...state.page.mission.report,
          formattedData,
          isSubmitLoading: true,
          isLoading: true,
        }
      }
    }
  })),
  on(TechInventoryActions.submitMissionReportSuccess, (state, {response}) => {
    return ({
      ...state,
      page: {
        ...state.page,
        mission: {
          ...state.page.mission,
          detail: {
            ...state.page.mission.detail,
            reportId: response.missionReportId
          },
          report: {
            ...state.page.mission.report,
            data: undefined,
            isSubmitLoading: false,
            isLoading: false
          }
        }
      }
    })
  }),
  on(TechInventoryActions.submitMissionReportFail, (state) => ({
    ...state,
    page: {
      ...state.page,
      mission: {
        ...state.page.mission,
        report: {
          ...state.page.mission.report,
          isSubmitLoading: false,
          isLoading: false
        }
      }
    }
  })),
  on(TechInventoryActions.requestReportStereotypesByEntity, (state) => {
    return ({
      ...state,
      page: {
        ...state.page,
        mission: {
          ...state.page.mission,
          report: {
            ...state.page.mission.report,
            stereotypeTypes: {
              ...state.page.mission.report.stereotypeTypes,
              isLoading: true
            }
          }
        }
      }
    })
  }),
  on(TechInventoryActions.requestReportStereotypesByEntityRefresh, (state, {data}) => {
    return ({
      ...state,
      page: {
        ...state.page,
        mission: {
          ...state.page.mission,
          report: {
            ...state.page.mission.report,
            stereotypeTypes: {
              data: [...data.items],
              paging: data.paging,
              isLoading: false
            }
          }
        }
      }
    })
  }),
  on(TechInventoryActions.requestReportStereotypesByEntitySuccess, (state, {data}) => {
    return ({
      ...state,
      page: {
        ...state.page,
        mission: {
          ...state.page.mission,
          report: {
            ...state.page.mission.report,
            stereotypeTypes: {
              data: [...(state.page.mission.report.stereotypeTypes.data || []), ...data.items],
              paging: data.paging,
              isLoading: false
            }
          }
        }
      }
    })
  }),
  on(TechInventoryActions.requestReportStereotypesByEntityFail, (state) => {
    return ({
      ...state,
      page: {
        ...state.page,
        mission: {
          ...state.page.mission,
          report: {
            ...state.page.mission.report,
            stereotypeTypes: {
              ...state.page.mission.report.stereotypeTypes,
              isLoading: false
            }
          }
        }
      }
    })
  }),
  on(TechInventoryActions.requestReportAllStereotypesByEntitySuccess, (state, {data}) => {
    return ({
      ...state,
      page: {
        ...state.page,
        mission: {
          ...state.page.mission,
          isLoading: true,
          report: {
            ...state.page.mission.report,
            stereotypeTypes: {
              ...state.page.mission.report.stereotypeTypes,
              allStereotypes: data,
              isLoading: false
            }
          }
        }
      }
    })
  }),
  on(TechInventoryActions.requestReportStereotypesByEntityFormat, (state, {formattedData}) => {
    return ({
      ...state,
      page: {
        ...state.page,
        mission: {
          ...state.page.mission,
          isLoading: false,
          report: {
            ...state.page.mission.report,
            stereotypeTypes: {
              ...state.page.mission.report.stereotypeTypes,
              formattedData: formattedData,
              isLoading: false
            }
          }
        }
      }
    })
  }),
  // tree
  on(TechInventoryActions.requestMenuTreeMainListPaginated, (state) => {
    return ({
      ...state,
      page: {
        ...state.page,
        menuTree: {
          ...state.page.menuTree,
          list: {
            ...state.page.menuTree.list,
            isLoading: true
          }
        },
        mission: {
          ...state.page.mission,
        }
      }
    })
  }),
  on(TechInventoryActions.requestMenuTreeMainListPaginatedCancel, (state) => ({
    ...state,
    page: {
      ...state.page,
      menuTree: {
        ...state.page.menuTree,
        list: {
          ...state.page.menuTree.list,
          isLoading: false
        }
      },
      mission: {
        ...state.page.mission,
      }
    }
  })),
  on(TechInventoryActions.requestMenuTreeMainListPaginatedRefresh, (state, {data}) => ({
    ...state,
    page: {
      ...state.page,
      menuTree: {
        ...state.page.menuTree,
        list: {
          ...state.page.menuTree.list,
          // Important! include the current state.items and the data.items to create a new array with the
          // existing ones and the new one that is requested
          items: [...data.items],
          paging: data.paging,
          isLoading: false
        }
      },
      mission: {
        ...state.page.mission,
      }
    }
  })),
  on(TechInventoryActions.requestMenuTreeMainListPaginatedSuccess, (state, {data}) => {
    return ({
      ...state,
      page: {
        ...state.page,
        menuTree: {
          ...state.page.menuTree,
          list: {
            ...state.page.menuTree.list,
            // Important! include the current state.items and the data.items to create a new array with the
            // existing ones and the new one that is requested
            items: [...state.page.menuTree.list.items, ...data.items],
            paging: data.paging,
            isLoading: false
          }
        },
        mission: {
          ...state.page.mission,
        }
      }
    })
  }),
  on(TechInventoryActions.requestMenuTreeMainListPaginatedFail, (state) => ({
    ...state,
    page: {
      ...state.page,
      menuTree: {
        ...state.page.menuTree,
        list: {
          ...state.page.menuTree.list,
          isLoading: false
        }
      },
      mission: {
        ...state.page.mission,
      }
    }
  })),
  on(TechInventoryActions.requestMenuTreeResourcePaginated, (state, {mainItemId}) => {
    return ({
      ...state,
      page: {
        ...state.page,
        menuTree: {
          ...state.page.menuTree,
          list: {
            ...state.page.menuTree.list,
            items: state.page.menuTree.list.items.map(
              (item: TechInventoryPageMenuTreeItemListMainItem<StereotypeListDto | LocationListDto | ResourceModelListDto>) =>
                getMainItemId(item.mainItem) === mainItemId ? {...item, mainItemIsLoading: true} : item
            ),
          }
        },
        mission: {
          ...state.page.mission,
        }
      }
    })
  }),
  on(TechInventoryActions.requestMenuTreeResourcePaginatedRefresh, (state, {mainItemId, data}) => {
    return ({
      ...state,
      page: {
        ...state.page,
        menuTree: {
          ...state.page.menuTree,
          list: {
            ...state.page.menuTree.list,
            items: state.page.menuTree.list.items.map(
              (item: TechInventoryPageMenuTreeItemListMainItem<StereotypeListDto | LocationListDto | ResourceModelListDto>) =>
                getMainItemId(item.mainItem) === mainItemId ? {...data, mainItemIsLoading: false} : item
            ),
          }
        },
        mission: {
          ...state.page.mission,
        }
      }
    })
  }),
  on(TechInventoryActions.requestMenuTreeResourcePaginatedSuccess, (state, {mainItemId, data}) => {
    return ({
      ...state,
      page: {
        ...state.page,
        menuTree: {
          ...state.page.menuTree,
          list: {
            ...state.page.menuTree.list,
            items: state.page.menuTree.list.items.map(
              (item: TechInventoryPageMenuTreeItemListMainItem<StereotypeListDto | LocationListDto | ResourceModelListDto>) =>
                getMainItemId(item.mainItem) === mainItemId ? {
                  ...item, ...data,
                  mainItemIsLoading: false,
                  resourceItems: [...(item.resourceItems || []), ...(data.resourceItems || [])]
                } : item
            ),
          }
        },
        mission: {
          ...state.page.mission,
        }
      }
    })
  }),
  on(TechInventoryActions.requestMenuTreeResourcePaginatedCancel, (state, {mainItemId}) => {
    return ({
      ...state,
      page: {
        ...state.page,
        menuTree: {
          ...state.page.menuTree,
          list: {
            ...state.page.menuTree.list,
            items: state.page.menuTree.list.items.map(
              (item: TechInventoryPageMenuTreeItemListMainItem<StereotypeListDto | LocationListDto | ResourceModelListDto>) =>
                getMainItemId(item.mainItem) === mainItemId ? {...item, mainItemIsLoading: false} : item
            ),
          }
        },
        mission: {
          ...state.page.mission,
        }
      }
    })
  }),
  on(TechInventoryActions.requestMenuTreeResourcePaginatedFail, (state, {mainItemId}) => {
    return ({
      ...state,
      page: {
        ...state.page,
        menuTree: {
          ...state.page.menuTree,
          list: {
            ...state.page.menuTree.list,
            items: state.page.menuTree.list.items.map(
              (item: TechInventoryPageMenuTreeItemListMainItem<StereotypeListDto | LocationListDto | ResourceModelListDto>) =>
                getMainItemId(item.mainItem) === mainItemId ? {...item, mainItemIsLoading: false} : item
            ),
          }
        },
        mission: {
          ...state.page.mission,
        }
      }
    })
  }),
);

function isStereotype(mainItem: StereotypeListDto | LocationListDto | ResourceModelListDto): mainItem is StereotypeListDto {
  return "stereotypeId" in mainItem && !(mainItem as LocationListDto).locationId;
}

function isLocation(mainItem: StereotypeListDto | LocationListDto | ResourceModelListDto): mainItem is LocationListDto {
  return "locationId" in mainItem && !("modelId" in mainItem);
}

function getMainItemId(mainItem: StereotypeListDto | LocationListDto | ResourceModelListDto): number | undefined {
  if (isStereotype(mainItem)) return mainItem.stereotypeId;
  if (isLocation(mainItem)) return mainItem.locationId;
  return (mainItem as ResourceModelListDto).resourceModelId;
}
