import {EffectsBase} from "@core/base.effects";
import {Observable} from "rxjs";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {Store} from "@ngrx/store";
import {Injectable} from "@angular/core";
import {InventoryRequestPagination} from "@tech/pages/inventory/interfaces/inventory-request-pagination.interface";
import {TechInventoryActions} from "@tech/pages/inventory/store/tech-iventory.actions-type";
import {MissionCompleteDto, MissionDto, MissionReportDto, MissionState, StereotypeDto} from "@server-models";
import {TreeMenuSegmentEnum} from "@tech/pages/inventory/enums/tree-menu-segment.enum";

@Injectable({
  providedIn: 'root'
})
export abstract class TechInventoryEffectsBase extends EffectsBase {

  protected constructor(store: Store, rawActions$: Actions) {
    super(store, rawActions$);
  }

  abstract postMissionPaginated(action: Observable<InventoryRequestPagination>): Observable<any>;

  abstract postMissionPaginatedSuccess(action: Observable<any>): Observable<any>;

  // detail
  abstract navigateToMissionDetail(action: Observable<MissionCompleteDto>): Observable<any>;

  abstract getMissionDetailById(action: Observable<{ missionId: string; reportDemand: boolean; existingUrl: string[] }>): Observable<any>;

  abstract getMissionDetailByIdSuccess(action: Observable<{ data: MissionDto; reportDemand: boolean; existingUrl: string[] }>): Observable<any>;

  abstract getMissionDetailStereotypeById(action: Observable<{ id: number; reportDemand: boolean; existingUrl: string[] }>): Observable<any>;

  abstract getMissionDetailStereotypeByIdSuccess(action: Observable<{ stereotype: StereotypeDto; cacheGuid: string; reportDemand: boolean; existingUrl: string[] }>): Observable<any>;

  abstract setMissionState(action: Observable<{ id: number; missionToChange: MissionState }>): Observable<any>;

  abstract setMissionStateSuccess(action: Observable<any>): Observable<any>;

  // report
  abstract getMissionReportById(action: Observable<{ reportId: string }>): Observable<any>;

  abstract getMissionReportPreviewById(action: Observable<{ missionId: string }>): Observable<any>;

  abstract getMissionReportArticleList(action: Observable<any>): Observable<any>;

  abstract submitMissionReport(action: Observable<{ missionReport: MissionReportDto, existingReportId: boolean }>): Observable<any>;

  abstract submitMissionReportSuccess(action: Observable<{ response: MissionReportDto }>): Observable<any>;

  abstract navigateToInventory(action: Observable<any>): Observable<any>;

  abstract requestReportStereotypesByEntity(action: Observable<any>): Observable<any>;

  abstract requestReportStereotypesByEntitySuccess(action: Observable<any>): Observable<any>;

  abstract requestReportStereotypesByEntityRefresh(action: Observable<any>): Observable<any>;

  abstract requestReportAllStereotypesByEntitySuccess(action: Observable<any>): Observable<any>;

  abstract selectedStereotypeReportType(action: Observable<{reportType: any}>): Observable<any>;

  // tree
  abstract requestMenuTreeMainListPaginated(action: Observable<{ currentSegment: TreeMenuSegmentEnum; request: InventoryRequestPagination }>): Observable<any>;

  abstract requestMenuTreeResourcePaginated(action: Observable<{ mainItemId: number; request: InventoryRequestPagination }>): Observable<any>;


  postMissionPaginated$ = createEffect(() => this.actions().pipe(
    ofType(TechInventoryActions.postMissionPagePaginated),
    action => this.postMissionPaginated(action)
  ));

  postMissionPaginatedSuccess$ = createEffect(() => this.actions().pipe(
    ofType(TechInventoryActions.postMissionPagePaginatedSuccess),
    action => this.postMissionPaginatedSuccess(action)
  ));

  navigateToMissionDetail$ = createEffect(() => this.actions().pipe(
    ofType(TechInventoryActions.navigateToMissionDetail),
    action => this.navigateToMissionDetail(action)
  ), {dispatch: false});

  getMissionDetailById$ = createEffect(() => this.actions().pipe(
    ofType(TechInventoryActions.getMissionDetailById),
    action => this.getMissionDetailById(action)
  ));

  getMissionDetailByIdSuccess$ = createEffect(() => this.actions().pipe(
    ofType(TechInventoryActions.getMissionDetailByIdSuccess),
    action => this.getMissionDetailByIdSuccess(action)
  ));

  getMissionDetailStereotypeById$ = createEffect(() => this.actions().pipe(
    ofType(TechInventoryActions.getMissionDetailStereotypeById),
    action => this.getMissionDetailStereotypeById(action)
  ));

  getMissionDetailStereotypeByIdSuccess$ = createEffect(() => this.actions().pipe(
    ofType(TechInventoryActions.getMissionDetailStereotypeByIdSuccess),
    action => this.getMissionDetailStereotypeByIdSuccess(action)
  ));

  setMissionState$ = createEffect(() => this.actions().pipe(
    ofType(TechInventoryActions.setMissionState),
    action => this.setMissionState(action)
  ));

  setMissionStateSuccess$ = createEffect(() => this.actions().pipe(
    ofType(TechInventoryActions.setMissionStateSuccess),
    action => this.setMissionStateSuccess(action)
  ), {dispatch: false});

  // report
  getMissionReportById$ = createEffect(() => this.actions().pipe(
    ofType(TechInventoryActions.getMissionReportById),
    action => this.getMissionReportById(action)
  ));

  getMissionReportPreviewById$ = createEffect(() => this.actions().pipe(
    ofType(TechInventoryActions.getMissionReportPreviewById),
    action => this.getMissionReportPreviewById(action)
  ));

  getMissionReportArticleList$ = createEffect(() => this.actions().pipe(
    ofType(TechInventoryActions.getMissionReportArticleList),
    action => this.getMissionReportArticleList(action)
  ));

  submitMissionReport$ = createEffect(() => this.actions().pipe(
    ofType(TechInventoryActions.submitMissionReport),
    action => this.submitMissionReport(action)
  ));

  submitMissionReportSuccess$ = createEffect(() => this.actions().pipe(
    ofType(TechInventoryActions.submitMissionReportSuccess),
    action => this.submitMissionReportSuccess(action)
  ), {dispatch: false});

  requestReportStereotypesByEntity$ = createEffect(() => this.actions().pipe(
    ofType(TechInventoryActions.requestReportStereotypesByEntity),
    action => this.requestReportStereotypesByEntity(action)
  ));

  requestReportStereotypesByEntitySuccess$ = createEffect(() => this.actions().pipe(
    ofType(TechInventoryActions.requestReportStereotypesByEntitySuccess),
    action => this.requestReportStereotypesByEntitySuccess(action)
  ));

  requestReportStereotypesByEntityRefresh$ = createEffect(() => this.actions().pipe(
    ofType(TechInventoryActions.requestReportStereotypesByEntityRefresh),
    action => this.requestReportStereotypesByEntityRefresh(action)
  ));

  requestReportAllStereotypesByEntitySuccess$ = createEffect(() => this.actions().pipe(
    ofType(TechInventoryActions.requestReportAllStereotypesByEntitySuccess),
    action => this.requestReportAllStereotypesByEntitySuccess(action)
  ));

  selectedStereotypeReportType$ = createEffect(() => this.actions().pipe(
    ofType(TechInventoryActions.selectedStereotypeReportType),
    action => this.selectedStereotypeReportType(action)
  ));

  navigateToInventory$ = createEffect(() => this.actions().pipe(
    ofType(TechInventoryActions.navigateToInventory),
    action => this.navigateToInventory(action)
  ), {dispatch: false});

  requestMenuTreeMainListPaginated$ = createEffect(() => this.actions().pipe(
    ofType(TechInventoryActions.requestMenuTreeMainListPaginated),
    action => this.requestMenuTreeMainListPaginated(action)
  ));

  requestMenuTreeResourcePaginated$ = createEffect(() => this.actions().pipe(
    ofType(TechInventoryActions.requestMenuTreeResourcePaginated),
    action => this.requestMenuTreeResourcePaginated(action)
  ));
}
