import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {
  ArticleListDto,
  ChangeStateOfMissionDto,
  FilterDto, LocationListDto,
  MissionCompleteDto,
  MissionDto, MissionReportDto, MissionReportPreviewDto,
  MissionState, Orders, ResourceModelListDto
} from "@server-models";
import {OrgaResponse} from "@shared/interfaces/orga-response.interface";
import {map} from "rxjs/operators";
import {PaginationParams} from "@shared/interfaces/pagination-params.interface";
import {RequestOptions} from "@shared/interfaces/request-options.interface";
import {TechHttpClientBaseApiService} from "@tech/services/http-client-base-api/tech-http-client-base-api.service";

@Injectable({
  providedIn: 'root',
})
export class TechInventoryApiService extends TechHttpClientBaseApiService {


  pagePostMissionPaginated(paramsPaginated: PaginationParams, body: FilterDto[]): Observable<OrgaResponse<MissionCompleteDto[]>> {
    const options: RequestOptions = {
      params: {
        PageSize: paramsPaginated.pageSize.toString(),
        PageNumber: paramsPaginated.pageNumber.toString(),
        Cols: paramsPaginated.cols,
        SotField: paramsPaginated.sortField as string,
        Sort: paramsPaginated.sort!.toString()
      }
    }
    return super.post<OrgaResponse<MissionCompleteDto[]>>(`/tech/mission/filter`,
      undefined, body, options).pipe(
      map((res: OrgaResponse<MissionCompleteDto[]>) => res)
    );
  }

  getMissionDetailById(id: string): Observable<MissionDto> {
    const options = {
      params: {
        SortField: 'plannedStart',
        Sort: Orders.Descending.toString(),
      }
    }
    return super.get<MissionDto>(`/tech/mission/${id}`,undefined, options);
  }

  postChangeMissionStateById(id: number, newState: MissionState): Observable<void> {
    const body: ChangeStateOfMissionDto = {
      missionId: id,
      wantedState: newState
    };
    return super.post<void>(`/tech/mission/${id}/changeState`, undefined, body);
  }

  postLocationFilter(params: PaginationParams, body: any): Observable<OrgaResponse<LocationListDto[]>> {
    const options: RequestOptions = {
      params: {
        PageSize: params.pageSize.toString(),
        PageNumber: params.pageNumber.toString(),
        Cols: params.cols
      }
    }
    return super.post<OrgaResponse<LocationListDto[]>>(`/tech/location/filter`, undefined, body, options);
  }

  postModelFilter(params: PaginationParams, body: any): Observable<OrgaResponse<ResourceModelListDto[]>> {
    const options: RequestOptions = {
      params: {
        PageSize: params.pageSize.toString(),
        PageNumber: params.pageNumber.toString(),
        Cols: params.cols,
        ...(params.sortField && { sortField: params.sortField.toString() }),
        ...(params.sort !== undefined && params.sort !== null && { sort: params.sort.toString() })
      }
    }
    return super.post<OrgaResponse<ResourceModelListDto[]>>(`/tech/v2/model/filter`, undefined, body, options);
  }

  getMissionReportById(reportId: string): Observable<MissionReportDto> {
    return super.get<MissionReportDto>(`/tech/missionreport/${reportId}`, undefined);
  }

  postSubmitMissionReport(missionReport: MissionReportDto): Observable<MissionReportDto> {
    return super.post<MissionReportDto>(`/tech/missionreport`, undefined, missionReport);
  }

  putSubmitMissionReportById(missionReport: MissionReportDto): Observable<MissionReportDto> {
    return super.put<MissionReportDto>(`/tech/missionreport/${missionReport.missionReportId}`, undefined, missionReport);
  }

  getMissionReportPreviewById(missionId: string): Observable<MissionReportPreviewDto> {
    return super.get<MissionReportPreviewDto>(`/tech/mission/${missionId}/report/preview`, undefined);
  }

  postMissionReportArticleFilter(body: any): Observable<OrgaResponse<ArticleListDto[]>> {
    const options: RequestOptions = {
      params: {
        PageSize: '500',
        PageNumber: '1',
        Cols: 'Content',
        SortField: 'name',
        Sort: '1'
      }
    }
    return super.post<OrgaResponse<ArticleListDto[]>>(`/tech/article/filter`, undefined, body, options);
  }


}
