<ion-content>
  <div class="thank-you-container">
    <ion-icon color="success" name="checkmark-circle-outline" class="thank-you-icon"></ion-icon>
    <ion-text>
      <h2>{{ 'SUCCESS_PAGE.MISSION.REPORT.THANKS.TITLE' | translate}}</h2>
      <ng-container *ngIf="isPost; else putMessage">
        <p>{{ 'SUCCESS_PAGE.MISSION.REPORT.THANKS.POST.SUCCESS_MESSAGE' | translate}}</p>
      </ng-container>
      <ng-template #putMessage>
        <p>{{ 'SUCCESS_PAGE.MISSION.REPORT.THANKS.PUT.SUCCESS_MESSAGE' | translate}}</p>
      </ng-template>
    </ion-text>
    <ion-buttons>
      <ion-button color="primary" fill="outline" (click)="back()">
          {{ 'SUCCESS_PAGE.MISSION.REPORT.THANKS.BACK' | translate }} <ion-icon slot="start" name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </div>
</ion-content>
