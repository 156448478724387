<ion-spinner *ngIf="loading$ | async"></ion-spinner>

<div id="mapContainer">
  <google-map height="100%" width="100%" [options]="options" (mapInitialized)="initMap($event)">
    <!-- User position marker -->
    <map-marker *ngIf="currentOrigin"
                [icon]="userPositionIcon"
                [position]="currentOrigin"
                [options]="{zIndex: 0}"
    />
    <!-- Mission Markers -->
    <map-marker *ngFor="let nearby of (nearbyMissions$ | async)?.nearby; index as i"
                [position]="nearby.point"
                [options]="{zIndex:i + 1}"
                [label]="nearby.missions?.length?.toString() ?? ''"
                (mapClick)="onMarkerClick(nearby)"
    />
  </google-map>
</div>

<ion-modal #detailModal [initialBreakpoint]="0.5" [breakpoints]="[0, 0.5, 1]">
  <ng-template>
    <ng-container *ngIf="currentNearbyMissionsForModal$.getValue() as nearby">
      <ion-content class="ion-padding">
        <ion-list lines="none">
          <ion-item class="ion-no-padding">
            <ion-icon slot="start" name="location" color="medium" />
            <ion-label>
              <h2>{{ nearby.location.name }}</h2>
              <p>{{ nearby.location.address }}</p>
            </ion-label>
          </ion-item>
        </ion-list>
        <ion-list lines="none">
          <ion-item class="ion-no-padding" *ngFor="let mission of nearby.missions">
            <ion-icon slot="start" name="hammer" color="medium" />
            <ion-label>
              <ion-button class="ion-no-padding ion-no-margin"
                          fill="clear"
                          color="primary"
                          (click)="openMissionDetail(mission)">
                <h2>{{ mission.title }}</h2>
              </ion-button>
              <p *ngIf="mission.sourceResource">
                {{ getResourceInfo(mission.sourceResource) }}
              </p>
            </ion-label>
          </ion-item>
        </ion-list>
      </ion-content>
    </ng-container>
  </ng-template>
</ion-modal>
