import {Component, OnInit} from '@angular/core';
import {IonicModule, ViewWillEnter} from "@ionic/angular";
import {AsyncPipe, NgClass, NgForOf, NgIf} from "@angular/common";
import {TranslateModule} from "@ngx-translate/core";
import {
  AppEntityType, CombineOperator,
  FilterDto,
  FilterOperators, FilterTypes, GroupedFilterDto, LocationListDto,
  MissionCompleteDto,
  MissionState, ResourceModelListDto,
  ResourceType,
  StereotypeListDto
} from "@server-models";
import {BaseIssueCardComponent} from "@shared/components/issues/components/base-issue-card/base-issue-card.component";
import {ButtonTechSideMenuComponent} from "@tech/components/button-tech-side-menu/button-tech-side-menu.component";
import {filter, first, Observable, of, Subscription} from 'rxjs';
import {tap} from "rxjs/operators";
import {select, Store} from "@ngrx/store";
import {PaginationParams} from "@shared/interfaces/pagination-params.interface";
import {Pagination} from "@shared/interfaces/pagination.interface";
import {TechInventoryActions} from "@tech/pages/inventory/store/tech-iventory.actions-type";
import {
  TechInventoryPageMenuTreeItemListMainItem,
  TechInventoryState, TechInventoryStatePageMenuTree
} from "@tech/pages/inventory/store/tech-invetory.state";
import {TechInventorySelectors} from "@tech/pages/inventory/store/tech-inventory.selector-type";
import {
  TechInventoryMissionListComponent
} from "@tech/pages/inventory/components/tech-inventory-mission-list/tech-inventory-mission-list.component";
import {
  TechInventorySettingsComponent
} from "@tech/pages/inventory/components/tech-inventory-settings/tech-inventory-settings.component";
import {AssignedUserEnum} from "@tech/pages/inventory/enums/segment-settings.emun";
import {TechInventoryService} from "@tech/pages/inventory/services/tech-inventory.service";
import {MissionFilterService} from "@tech/pages/inventory/services/mission-filter.service";
import {FormsModule} from "@angular/forms";
import {TreeMenuSegmentEnum} from "@tech/pages/inventory/enums/tree-menu-segment.enum";
import {
  TechChildTreeResourcesComponent
} from "@tech/pages/inventory/components/child-tree-resources/tech-child-tree-resources.component";
import {MenuTreeFilterService} from "@tech/pages/inventory/services/menu-tree-filter.service";
import {TechInventoryHelperService} from "@tech/pages/inventory/services/iventory-helper.service";
import {
  TechInventoryMissionMapComponent
} from '@tech/pages/inventory/components/tech-inventory-mission-map/tech-inventory-mission-map.component';

@Component({
  selector: 'app-inventory',
  templateUrl: './inventory.page.html',
  styleUrls: ['./inventory.page.scss'],
  imports: [
    IonicModule,
    AsyncPipe,
    BaseIssueCardComponent,
    NgForOf,
    NgIf,
    TranslateModule,
    BaseIssueCardComponent,
    ButtonTechSideMenuComponent,
    TechInventoryMissionListComponent,
    TechInventorySettingsComponent,
    FormsModule,
    TechChildTreeResourcesComponent,
    NgClass,
    TechInventoryMissionMapComponent
  ],
  standalone: true
})
export class InventoryPage implements OnInit, ViewWillEnter {
  protected readonly MissionState = MissionState;
  protected readonly SegmentSettingsEnum = AssignedUserEnum;
  protected readonly TreeMenuSegmentEnum = TreeMenuSegmentEnum;

  paginationParams: PaginationParams;
  paginationTreeStereotypeParams: PaginationParams;
  paginationResourceTreeParams: PaginationParams;

  paginationBody: FilterDto[];
  defaultPaginationBody: FilterDto[];

  ownLoad: boolean;
  ownTreeLoad: boolean

  infinityLoading: boolean;
  infinityMainTreeLoading: boolean;

  listMode: string;

  missionDataList$: Observable<MissionCompleteDto[]>;
  missionStereotypes$: Observable<StereotypeListDto[]>;
  currentStereotypes: StereotypeListDto[];
  missionFilter$?: Observable<any>;

  inventoryMenuTree$: Observable<TechInventoryStatePageMenuTree>;
  currentTree: TechInventoryPageMenuTreeItemListMainItem<StereotypeListDto | LocationListDto | ResourceModelListDto>[];
  filterResourceType: (FilterDto | GroupedFilterDto)[];

  pagingData$: Observable<Pagination>;

  isLoading$: Observable<boolean>;
  isLoadingMenuTreeMainList$: Observable<boolean>;
  isLoadingMenuTreeResourceById$: Observable<boolean>;

  openSubscriptionList$: Subscription[];
  firstLoad: boolean;

  selectedSegmentSettings: AssignedUserEnum | null;
  selectedTreeMenuSegment: TreeMenuSegmentEnum;

  selectedItem: any;
  selectedParentId: number | undefined;

  constructor(
    private _store: Store<TechInventoryState>,
    private _inventoryService: TechInventoryService,
    private _missionFilterService: MissionFilterService,
    private _menuTreeFilterService: MenuTreeFilterService,
    public inventoryHelperService: TechInventoryHelperService
  ) {
    this.missionDataList$ = of();

    this.inventoryMenuTree$ = of();

    this.missionStereotypes$ = of();

    this.pagingData$ = of();

    this.isLoading$ = of();
    this.isLoadingMenuTreeMainList$ = of();
    this.isLoadingMenuTreeResourceById$ = of();


    this.openSubscriptionList$ = [];

    this.ownLoad = false;
    this.ownTreeLoad = false;

    this.infinityLoading = false;
    this.infinityMainTreeLoading = false;

    this.paginationParams = {
      pageNumber: 1,
      pageSize: 10,
      cols: 'Location',
      sortField: 'plannedStart',
      sort: 1
    };
    this.paginationTreeStereotypeParams = {
      pageNumber: 1,
      pageSize: 30,
      cols: 'Content',
      entityType: AppEntityType.Resource,
      archived: 'false'
    };
    this.paginationResourceTreeParams = {
      pageNumber: 1,
      pageSize: 10,
      cols: 'Content'
    };

    this.defaultPaginationBody = [];
    this.paginationBody = [];
    this.listMode = "list";

    this.currentStereotypes = [];
    this.currentTree = <TechInventoryPageMenuTreeItemListMainItem<StereotypeListDto | LocationListDto | ResourceModelListDto>[]>{};
    this.filterResourceType = [];
    this.firstLoad = true;

    this.selectedSegmentSettings = AssignedUserEnum.Mine;
    this.selectedTreeMenuSegment = TreeMenuSegmentEnum.Type;
  }

  ngOnInit() {
    this.missionDataList$ = this._store.pipe(select(TechInventorySelectors.selectTechInventoryPageMissionList));

    this.inventoryMenuTree$ = this._store.pipe(select(TechInventorySelectors.selectTechInventoryPageMenuTree))

    this.missionStereotypes$ = this._store.pipe(select(TechInventorySelectors.selectTechInventoryPageMissionStereotypes));

    this.pagingData$ = this._store.pipe(select(TechInventorySelectors.selectTechInventoryPageMissionPagination));

    this.isLoading$ = this._store.pipe(select(TechInventorySelectors.selectTechInventoryPageMissionLoading));
    this.isLoadingMenuTreeMainList$ = this._store.pipe(select(TechInventorySelectors.selectTechInventoryPageMenuTreeMainListLoading));

    this._inventoryService.setSetting({
      state: MissionState.Instructed
    });

    this.missionFilter$ = this._missionFilterService.filters$;

    this._missionFilterService.filters$.subscribe((filters) => {
      this.paginationParams.pageNumber = 1;
      const refresh = !this.firstLoad;
      this.loadInventoryMissionByPagination(filters, refresh)
    });

    this.missionStereotypes$.subscribe((data) => {
      if (data) {
        this.currentStereotypes = this._filterByNoArchived(data);
      }
    });

    this._menuTreeFilterService.paramSettings$.subscribe(({segment}) => {
      this.selectedTreeMenuSegment = segment!;
    });
    this._menuTreeFilterService.request$.subscribe(({param, filter, refresh}) => {
      const definitiveRefresh = this.firstLoad ? this.firstLoad : refresh;
      this.loadInventoryTreeMenuMainListByPagination(param, filter, definitiveRefresh);
    });
  }

  ionViewWillEnter() {
    if (!this.firstLoad) {
      this._missionFilterService.filterSettings$.next({
        ...this._missionFilterService.filterSettings$.getValue(),
      });

      this._menuTreeFilterService.filterSettings$.next({
        ...this._menuTreeFilterService.filterSettings$.getValue(),
      });
    }

    this.firstLoad = false;
  }

  /**
   * @name _filterByNoArchived
   * @description
   * filter stereotypes by non archived
   * @memberof InventoryPage
   * @param stereotypeList
   * @private
   */
  private _filterByNoArchived(stereotypeList: StereotypeListDto[]) {
    return stereotypeList.filter(stereotype => stereotype.isArchived !== true);
  }

  /**
   * @name loadInventoryMissionByPagination
   * @description
   * launch the action to load inventory mission by a post params and body
   * @memberof InventoryPage
   * @param filters
   * @param refresh
   */
  loadInventoryMissionByPagination(filters: FilterDto[], refresh: boolean = false) {
    this._store.dispatch((TechInventoryActions.postMissionPagePaginated({
      pagination: this.paginationParams,
      filters: filters,
      refresh
    })))
  }

  loadInventoryTreeMenuMainListByPagination(param: PaginationParams, filters: FilterDto[], refresh: boolean = false) {
    if (refresh) {
      this.paginationTreeStereotypeParams.pageNumber = 1;
      this.selectedItem = null;
    }
    this._store.dispatch((TechInventoryActions.requestMenuTreeMainListPaginated({
      currentSegment: this.selectedTreeMenuSegment,
      request: {
        pagination: param,
        filters: filters,
        refresh
      }
    })))
  }

  loadInventoryTreeMenuResourceByPagination(mainItemId: number, filters: (FilterDto | GroupedFilterDto)[], refresh: boolean = false) {
    this._store.dispatch((TechInventoryActions.requestMenuTreeResourcePaginated({
      mainItemId,
      request: {
        pagination: this.paginationResourceTreeParams,
        filters: filters,
        refresh
      }
    })));
  }

  loadMoreInventoryMission(event: any): void {
    this.paginationParams.pageNumber++;
    this.infinityLoading = true;
    this._missionFilterService.filters$
      .pipe(
        first(),
        tap(filters => {
          this.loadInventoryMissionByPagination(filters);
        })
      )
      .subscribe({
        complete: () => {
          this.isLoading$.pipe(
            filter(loader => !loader),
            tap(() => {
              event.target.complete();
              this.infinityLoading = false;
            })
          ).subscribe();
        },
        error: () => {
          event.target.complete();
          this.infinityLoading = false;
        }
      });
  }

  loadMoreInventoryTreeMenu(event: any) {
    this.paginationTreeStereotypeParams.pageNumber++;
    this.infinityMainTreeLoading = true;
    this._menuTreeFilterService.request$
      .pipe(
        first(),
        tap(({param, filter}) => {
          this.loadInventoryTreeMenuMainListByPagination(this.paginationTreeStereotypeParams, filter, false);
        })
      )
      .subscribe({
        complete: () => {
          this.isLoadingMenuTreeMainList$.pipe(
            filter(loader => !loader),
            tap(() => {
              event.target.complete();
              this.infinityMainTreeLoading = false;
            })
          ).subscribe();
        },
        error: () => {
          event.target.complete();
          this.infinityMainTreeLoading = false;
        }
      });
  }

  handleRefresh(event: any): void {
    if (this.ownLoad) {
      if (event && event.target) {
        event.target.complete();
      }
      return;
    }

    this.ownLoad = true;
    if (event) {
      event.target.disabled = true;
    }

    this.resetProperties();

    this.isLoading$.pipe(
      filter((loader: boolean) => !loader),
      tap(() => {
        if (event && event.target) {
          event.target.disabled = false;
          event.target.complete();
        }
        this.ownLoad = false;
      })
    ).subscribe();
  }

  /**
   * @name resetProperties
   * @description
   * reset the pagination pageNumber to 1, the pagination body to [] and reset all settings/segmentes filters
   * @memberof InventoryPage
   */
  resetProperties(): void {
    this.paginationParams.pageNumber = 1;
    this._inventoryService.resetSetting();
    this._missionFilterService.filterSettings$.next({
      ...this._missionFilterService.filterSettings$.getValue(),
      state: undefined,
      stereotypeId: undefined,
    });
  }

  /**
   * @name changeSegmentSetting
   * @description
   * emit event with selected segment value
   * @memberof InventoryPage
   * @param ev
   */
  changeSegmentSetting(ev: CustomEvent): void {
    const userEnumValue: AssignedUserEnum = ev.detail.value;
    this.selectedSegmentSettings = userEnumValue;
    this._missionFilterService.filterSettings$.next({
      ...this._missionFilterService.filterSettings$.getValue(),
      assignedUser: userEnumValue,
    });
  }

  changeTreeMenuSegment(ev: CustomEvent): void {
    const selectedSegment: TreeMenuSegmentEnum = ev.detail.value;
    this._menuTreeFilterService.requestSettings$.next({
        ...this._menuTreeFilterService.requestSettings$.getValue(),
        param: {segment: selectedSegment},
        filter: {segment: selectedSegment},
        refresh: true
      }
    );
    this._missionFilterService.filterSettings$.next({
      ...this._missionFilterService.filterSettings$.getValue(),
      stereotypeId: undefined,
      resource: undefined,
      parentResource: undefined
    });

  }

  setListMode(ev: string): void {
    this.listMode = ev;
  }

  openResources(item: TechInventoryPageMenuTreeItemListMainItem<StereotypeListDto | LocationListDto | ResourceModelListDto>, parentId: number) {
    this.selectedItem = this.inventoryHelperService.getMainItemId(this.selectedItem?.mainItem) === parentId ? null : item;
    if (this.selectedItem != null) {
      this.paginationResourceTreeParams.pageNumber = 1;
      this._setFilterResourceType(parentId.toString());
      this.loadInventoryTreeMenuResourceByPagination(parentId, this.filterResourceType, true);
      this._missionFilterService.filterSettings$.next({
        ...this._missionFilterService.filterSettings$.getValue(),
        parentResource: {
          type: this.selectedTreeMenuSegment,
          id: parentId
        }
      });
    } else {
      this._missionFilterService.filterSettings$.next({
        ...this._missionFilterService.filterSettings$.getValue(),
        stereotypeId: undefined,
        resource: undefined,
        parentResource: undefined
      });
    }

  }

  requestMore(ev: { paging: Pagination, parentId: number }) {
    this._missionFilterService.filters$
      .pipe(
        first(),
        tap(filters => {
          this.paginationResourceTreeParams.pageNumber++;
          this._setFilterResourceType(ev.parentId.toString());
          this.loadInventoryTreeMenuResourceByPagination(ev.parentId, this.filterResourceType);
        })
      )
      .subscribe();
  }

  _setFilterResourceType(parentId: string): void {
    switch (this.selectedTreeMenuSegment) {
      case TreeMenuSegmentEnum.Type: {
        this.filterResourceType = [
          {
            property: 'stereotypeId',
            value: parentId,
            operator: FilterOperators.Equal
          },
          {
            type: FilterTypes.Grouped,
            combinedAs: CombineOperator.Or,
            children: [
              <FilterDto>{
                type: FilterTypes.DataTransferObject,
                operator: FilterOperators.Equal,
                property: 'type',
                value: ResourceType.Device
              },
              <FilterDto>{
                type: FilterTypes.DataTransferObject,
                operator: FilterOperators.Equal,
                property: 'type',
                value: ResourceType.Virtual
              }
            ]
          }
        ];
        break;
      }
      case TreeMenuSegmentEnum.Location: {
        this.filterResourceType = [
          {
            property: 'location.locationId',
            value: parentId,
            operator: FilterOperators.Equal
          }
        ];
        break;
      }
      case TreeMenuSegmentEnum.Model: {
        this.filterResourceType = [
          {
            property: 'model.resourceModelId',
            value: parentId,
            operator: FilterOperators.Equal
          }
        ];
        break;
      }
    }

  }

  formatNameToDisplay(mainItem: StereotypeListDto | LocationListDto | ResourceModelListDto): string {
    switch (this.selectedTreeMenuSegment) {
      case TreeMenuSegmentEnum.Model: {
        const castMainItem = mainItem as ResourceModelListDto;
        return `${castMainItem.resourceManufacturer!.name} ${castMainItem.name}`
      }
      default: {
        return mainItem.name!
      }
    }
  }


  /**
   * @name trackById
   * @description
   * arrow fuction to help the DOM to keep the information related to where are the items are and dont scroll to up
   * on load more resources request, need to be arrow function because is using a service from the component so it
   * needs to keep the component context instead only ngfor loop context
   * @param index
   * @param item
   */
  trackById = (index: number, item: TechInventoryPageMenuTreeItemListMainItem<StereotypeListDto | LocationListDto | ResourceModelListDto>): number | undefined => {
    return this.inventoryHelperService.getMainItemId(item.mainItem);
  }
}
