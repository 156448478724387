import {Injectable} from "@angular/core";
import {Router} from "@angular/router";
import {AssignedUserEnum} from "@tech/pages/inventory/enums/segment-settings.emun";

@Injectable({
  providedIn: 'root',
})
export class TechInventoryMissionPagesService {

  private _selectedSegment: AssignedUserEnum;

  constructor(
    private _router: Router
  ) {
    this._selectedSegment = AssignedUserEnum.Mine;
  }

  set selectedSegment(userSelection: AssignedUserEnum) {
    this._selectedSegment = userSelection;
  }

  get selectedSegment() {
    return this._selectedSegment;
  }

  resetSegment() {
    this.selectedSegment = AssignedUserEnum.Mine;
  }

  changeSegmentSetting(currentSegment: any) {
    const currentUrl = this._router.url;
    const urlSegments = currentUrl.split('/');
    const idIndex = urlSegments.findIndex(segment => segment === 'mission') + 1;
    const id = urlSegments[idIndex];
    let targetRoute = '';

    if (!id) {
      this._router.navigate([`/tech/logged/inventory/mission`]);
    } else {
      switch (currentSegment) {
        case AssignedUserEnum.Mine:
          this.selectedSegment = AssignedUserEnum.Mine;
          targetRoute = `/tech/logged/inventory/mission/${id}/view`;
          break;
        case AssignedUserEnum.PrimaryIssue:
          this.selectedSegment = AssignedUserEnum.PrimaryIssue;
          targetRoute = `/tech/logged/inventory/mission/${id}/issue`;
          break;
        case AssignedUserEnum.Unassigned:
          this.selectedSegment = AssignedUserEnum.Unassigned;
          targetRoute = `/tech/logged/inventory/mission/${id}/report`;
          break;
        default:
          this.selectedSegment = AssignedUserEnum.Mine;
          targetRoute = `/tech/logged/inventory/mission/${id}/view`;
          break;
      }
      this._router.navigate([targetRoute]);
    }
  }

  checkSegmentByString(segment: string): AssignedUserEnum | undefined {
    switch (segment) {
      case 'view':
        return AssignedUserEnum.Mine
      case 'issue':
        return AssignedUserEnum.PrimaryIssue
      case 'report':
        return AssignedUserEnum.Unassigned
      default:
        return undefined
    }
  }
}
