import {createAction, props} from '@ngrx/store';
import {OrgaResponse} from '@shared/interfaces/orga-response.interface';
import {
  AppEntityType,
  LocationListDto,
  MissionCompleteDto,
  MissionDto, MissionReportDto, MissionReportPreviewDto,
  MissionState, ResourceModelListDto,
  StereotypeDto, StereotypeListDto,
} from '@server-models';
import {InventoryRequestPagination} from '@tech/pages/inventory/interfaces/inventory-request-pagination.interface';
import {
  TechInventoryPageMenuTreeItemList,
  TechInventoryPageMenuTreeItemListMainItem,
} from "@tech/pages/inventory/store/tech-invetory.state";
import {TreeMenuSegmentEnum} from "@tech/pages/inventory/enums/tree-menu-segment.enum";
import {
  CustomPropertySetFormatted
} from "@shared/components/custom-property/interfaces/custom-property-set-formatted.interface";

export const postMissionPagePaginated = createAction(
  '[Inventory-Tech] Post Mission Page Paginated Start',
  props<InventoryRequestPagination>()
);

export const postMissionPagePaginatedCancel = createAction(
  '[Inventory-Tech] Post Mission Page Paginated Cancel'
);

export const postMissionPagePaginatedRefresh = createAction(
  '[Inventory-Tech] Post Mission Page Paginated Refresh',
  props<{ data: OrgaResponse<MissionCompleteDto[]> }>()
);

export const postMissionPagePaginatedSuccess = createAction(
  '[Inventory-Tech] Post Mission Page Paginated Success',
  props<{ data: OrgaResponse<MissionCompleteDto[]> }>()
);

export const postMissionPagePaginatedDone = createAction(
  '[Inventory-Tech] Post Mission Page Paginated Done',
  props<{ data: any }>()
);

export const postMissionPagePaginatedFail = createAction(
  '[Inventory-Tech] Post Mission Page Paginated Fail',
  props<{ error: Error }>()
);

// Mission detail
export const navigateToMissionDetail = createAction(
  '[Inventory-Tech] Navigate to Mission Detail',
  props<any>()
);

export const getMissionDetailById = createAction(
  '[Inventory-Tech] Get Mission Detail Start',
  props<{ missionId: string, reportDemand: boolean, existingUrl: string[] }>()
);

export const getMissionDetailByIdSuccess = createAction(
  '[Inventory-Tech] Get Mission Detail Success',
  props<{ data: MissionDto, reportDemand: boolean, existingUrl: string[] }>()
);

export const getMissionDetailByIdFail = createAction(
  '[Inventory-Tech] Get Mission Detail Fail',
  props<any>()
);

export const getMissionDetailByIdDone = createAction(
  '[Inventory-Tech] Get Mission Detail Done',
  props<{ customPropertySets: CustomPropertySetFormatted[] }>()
);

export const getMissionDetailStereotypeById = createAction(
  '[Inventory-Tech] Get Mission Detail Stereotype By Id Start',
  props<{ id: number; reportDemand: boolean; existingUrl: string[] }>()
);

export const getMissionDetailStereotypeByIdSuccess = createAction(
  '[Inventory-Tech] Get Mission Detail Stereotype By Id Success',
  props<{ stereotype: StereotypeDto; cacheGuid: string; reportDemand: boolean; existingUrl: string[] }>()
);

export const getMissionDetailStereotypeByIdFail = createAction(
  '[Inventory-Tech] Get Mission Detail Stereotype By Id Fail',
  props<{ error: any }>()
);

export const setMissionState = createAction(
  '[Inventory-Tech] Set Mission State Start',
  props<{ id: number; missionToChange: MissionState }>()
);

export const setMissionStateSuccess = createAction(
  '[Inventory-Tech] Set Mission State Success',
  props<{ missionState: MissionState }>()
);

export const setMissionStateFail = createAction(
  '[Inventory-Tech] Set Mission State Fail',
  props<{ error: any }>()
);

// Mission report getMissionReportById
export const getMissionReportById = createAction(
  '[Inventory-Tech] Get Mission Report Start',
  props<{ reportId: string }>()
);

export const getMissionReportByIdSuccess = createAction(
  '[Inventory-Tech] Get Mission Report Success',
  props<{ data: MissionReportDto }>()
);

export const getMissionReportByIdFail = createAction(
  '[Inventory-Tech] Get Mission Report Fail',
  props<{ error: any }>()
);

export const getMissionReportByIdDone = createAction(
  '[Inventory-Tech] Get Mission Report Done',
  props<{ a: any }>()
);

export const getMissionReportPreviewById = createAction(
  '[Inventory-Tech] Get Mission Preview Report Start',
  props<{ missionId: string }>()
);

export const getMissionReportPreviewByIdReset = createAction(
  '[Inventory-Tech] Get Mission Preview Report Reset'
);

export const getMissionReportPreviewByIdSuccess = createAction(
  '[Inventory-Tech] Get Mission Preview Report Success',
  props<{ data: MissionReportPreviewDto }>()
);

export const getMissionReportPreviewByIdFail = createAction(
  '[Inventory-Tech] Get Mission Preview Report Fail',
  props<{ error: any }>()
);

export const getMissionReportPreviewByIdDone = createAction(
  '[Inventory-Tech] Get Mission Preview Report Done',
  props<{ a: any }>()
);

export const navigateToInventory = createAction(
  '[Inventory-Tech] Navigate To Inventory'
);

// Mission report article list
export const getMissionReportArticleList = createAction(
  '[Inventory-Tech] Get Mission Report Article Paginated Start',
  props<{ searchTerm?: string }>()
);

export const getMissionReportArticleListSuccess = createAction(
  '[Inventory-Tech] Get Mission Report Article Paginated Success',
  props<{ data: any }>()
);

export const getMissionReportArticleListFail = createAction(
  '[Inventory-Tech] Get Mission Report Article Paginated Fail',
  props<{ error: any }>()
);

export const submitMissionReport = createAction(
  '[Inventory-Tech] Submit Mission Report Start',
  props<{ missionReport: MissionReportDto, existingReportId: boolean, formattedData: any }>()
);

export const submitMissionReportSuccess = createAction(
  '[Inventory-Tech] Submit Mission Report Success',
  props<{ response: MissionReportDto, existingReportId: boolean }>()
);

export const submitMissionReportFail = createAction(
  '[Inventory-Tech] Submit Mission Report Fail',
  props<{ error: any }>()
);

// tree
export const requestMenuTreeMainListPaginated = createAction(
  '[Inventory-Tech] Request Menu Tree Main List Paginated Start',
  props<{ currentSegment: TreeMenuSegmentEnum, request: InventoryRequestPagination }>()
);

export const requestMenuTreeMainListPaginatedRefresh = createAction(
  '[Inventory-Tech] Request Menu Tree Main List Paginated Refresh',
  props<{ data: TechInventoryPageMenuTreeItemList<StereotypeListDto | LocationListDto | ResourceModelListDto> }>()
);

export const requestMenuTreeMainListPaginatedCancel = createAction(
  '[Inventory-Tech] Request Menu Tree Main List Paginated Cancel'
);

export const requestMenuTreeMainListPaginatedSuccess = createAction(
  '[Inventory-Tech] Request Menu Tree Main List Paginated Success',
  props<{ data: TechInventoryPageMenuTreeItemList<StereotypeListDto | LocationListDto | ResourceModelListDto> }>()
);

export const requestMenuTreeMainListPaginatedFail = createAction(
  '[Inventory-Tech] Request Menu Tree Main List Paginated Fail',
  props<{ error: any }>()
);

export const requestMenuTreeResourcePaginated = createAction(
  '[Inventory-Tech] Request Menu Tree Resource Paginated Start',
  props<{ mainItemId: number, request: InventoryRequestPagination }>()
);

export const requestMenuTreeResourcePaginatedCancel = createAction(
  '[Inventory-Tech] Request Menu Tree Resource Paginated Cancel',
  props<{ mainItemId: number }>()
);

export const requestMenuTreeResourcePaginatedRefresh = createAction(
  '[Inventory-Tech] Request Menu Tree Resource Paginated Refresh',
  props<{
    mainItemId: number,
    data: TechInventoryPageMenuTreeItemListMainItem<StereotypeListDto | LocationListDto | ResourceModelListDto>
  }>()
);

export const requestMenuTreeResourcePaginatedSuccess = createAction(
  '[Inventory-Tech] Request Menu Tree Resource Paginated Success',
  props<{
    mainItemId: number,
    data: TechInventoryPageMenuTreeItemListMainItem<StereotypeListDto | LocationListDto | ResourceModelListDto>
  }>()
);

export const requestMenuTreeResourcePaginatedFail = createAction(
  '[Inventory-Tech] Request Menu Tree Resource Paginated Fail',
  props<{ mainItemId: number, error: any }>()
);

export const requestReportStereotypesByEntity = createAction(
  '[Inventory-Tech] Request Stereotype By Entity Start',
  props<{ entityType: AppEntityType, request: InventoryRequestPagination }>()
);

export const requestReportStereotypesByEntityRefresh = createAction(
  '[Inventory-Tech] Request Stereotype By Entity Refresh',
  props<{ data: OrgaResponse<StereotypeListDto[]> }>()
);

export const requestReportStereotypesByEntitySuccess = createAction(
  '[Inventory-Tech] Request Stereotype By Entity Success',
  props<{ data: OrgaResponse<StereotypeListDto[]> }>()
);

export const requestReportStereotypesByEntityCancel = createAction(
  '[Inventory-Tech] Request Stereotype By Entity Cancel'
);

export const requestReportStereotypesByEntityFormat = createAction(
  '[Inventory-Tech] Request Stereotype By Entity Format',
  props<any>()
);

export const requestReportAllStereotypesByEntitySuccess = createAction(
  '[Inventory-Tech] Request All Stereotype By Entity Success',
  props<{ data: any }>()
);

export const selectedStereotypeReportType = createAction(
  '[Inventory-Tech] Selected Stereotype Report Type',
  props<{ reportType: any }>()
);

export const requestReportStereotypesByEntityDone = createAction(
  '[Inventory-Tech] Request Stereotype By Entity Done',
  props<any>()
);

export const requestReportStereotypesByEntityFail = createAction(
  '[Inventory-Tech] Request Stereotype By Entity Fail',
  props<{ error: any }>()
);


