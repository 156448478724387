<!--[class.badge-malfunction]="mission.type === 'Malfunction'"-->
<!--[class.badge-maintenance]="mission.type === 'Maintenance'"-->
<ion-card *ngFor="let mission of missionList" button
          (click)="navigateToDetail(mission)"
>
  <ion-card-header>
    <ion-item lines="none" detail class="missions">
      <ion-label class="missions">
        <ion-text>
          <h1>
            {{ mission.title }}
          </h1>
        </ion-text>
        <ion-text color="medium">
          <div class="mission-sub-header">
            <div>
              <span class="margin-right">#{{ mission.missionId }}</span>
              <span>
                {{ 'COMPONENTS.MISSIONS.PLANNED' | translate }}
              </span>
            </div>
            <div>
              {{ formatDates(mission.plannedStart, mission.plannedEnd) }}
            </div>
          </div>
        </ion-text>
      </ion-label>
      <ion-chip class="ion-hide-md-down" slot="end" style="--background: #eceef1">
        <app-mission-state-options [stateOption]="mission.state!"></app-mission-state-options>
      </ion-chip>
    </ion-item>
  </ion-card-header>

  <ion-card-content>
    <ion-item lines="none" class="mission-list-chip">
      <!-- Resource -->
      <ion-row class="mission-list-chip">
        <ion-col size-xs="6">
          <ion-chip class="mission-list-chip"
                    *ngIf="mission.sourceResource?.name || mission.sourceResource?.inventoryNumber">
            <ion-icon name="hammer"></ion-icon>
            <div>
              <div *ngIf="mission.sourceResource?.inventoryNumber">
                <ion-text color="medium">
                  {{ mission.sourceResource?.inventoryNumber }}
                </ion-text>
              </div>
              <div *ngIf="mission.sourceResource?.name">
                {{ mission.sourceResource?.name }}
              </div>
            </div>
          </ion-chip>
        </ion-col>
        <ion-col size-xs="6">
          <!-- Location -->
          <ion-chip class="mission-list-chip" *ngIf="mission.location?.name || mission.locationNumber">
            <ion-icon name="location"></ion-icon>
            <div>
              <div *ngIf="mission.locationNumber">
                <ion-text color="medium">
                  {{ mission.locationNumber }}
                </ion-text>
              </div>
              <div *ngIf="mission.location?.name">
                {{ mission.location?.name }}
              </div>
            </div>
          </ion-chip>
        </ion-col>
      </ion-row>
    </ion-item>
  </ion-card-content>
</ion-card>
