import { Injectable } from '@angular/core';
import { ModalControllerService } from '@shared/services/modal-controller.service';

import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { NewPasswordDialogComponent } from '@tech/pages/login/components/new-password-dialog/new-password-dialog.component';
import { Router } from '@angular/router';
import { AlertBaseControllerService } from '@shared/services/alert-controller.service';
import { MeDto } from '@server-models';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { LoginBaseStorageService } from '@shared/stores/login-base/services/login-base-storage.service';
import { RequestRegisterUserDialogComponent } from '@tech/pages/login/components/request-register-user-dialog/request-register-user-dialog.component';
import { PinRegisterUserDialogComponent } from '@tech/pages/login/components/pin-register-user-dialog/pin-register-user-dialog.component';
import { TechLoginState } from '@tech/pages/login/store/tech-login.state';
import { TechLoginActions } from '@tech/pages/login/store/tech-login.actions-type';
import { TenantSelectionModalComponent } from '@shared/components/tenant-selection-modal/tenant-selection-modal.component';

@Injectable({
  providedIn: 'root'
})
export class TechLoginSideEffectsService {
  constructor(
    private _store: Store<TechLoginState>,
    private _loginBaseStorageService: LoginBaseStorageService,
    private _alertControllerService: AlertBaseControllerService,
    private _modalControllerService: ModalControllerService,
    private _translationService: TranslateService,
    private _router: Router
  ) {
  }

  /**
   * @name multipleTenants
   * @description
   * checks if there are multiple tenants
   * @memberof TechLoginSideEffectsService
   * @param tokenInfo
   * @returns {boolean}
   */
  multipleTenants(tokenInfo: MeDto): boolean {
    const tenants = tokenInfo?.tenants;
    return tenants?.length! > 1;
  }

  /**
   * @name getTenants
   * @description
   * display modal if not tenantId existing else dispatch already existing tenant
   * @memberof TechLoginSideEffectsService
   * @param tokenInfo
   */
  getTenants(tokenInfo: MeDto): void {
    this._checkExistingTenants().subscribe((tenantId) => {

      if (!(!!tenantId)) {
        this._modalControllerService.showModal(TenantSelectionModalComponent, '', {
          data: tokenInfo.tenants
        });
      } else {
        this._store.dispatch(TechLoginActions.existingMultiTenant({tenantId: tenantId!}))
      }
    });
  }

  /**
   * @name _checkExistingTenants
   * @description
   * returns tenantId if exists
   * @memberof TechLoginSideEffectsService
   * @private
   * @returns {Observable<number | undefined>}
   */
  private _checkExistingTenants(): Observable<number | undefined> {
    return this._loginBaseStorageService.getSt().pipe(
      map((value) => value?.currentTenant?.tenantId)
    );
  }

  askRegistrationData(currentEmail: string) {
    this._modalControllerService.showModal(RequestRegisterUserDialogComponent, '', {
      currentEmail
    });
  }

  askRegistrationPinGuest(passwordBase64: string, email: string) {
    this._modalControllerService.closeModal();
    this._modalControllerService.showModal(PinRegisterUserDialogComponent, '', {
      passwordBase64,
      email
    });
  }

  /**
   * @name newPasswordDialog
   * @description
   * display a modal of new password
   * @memberof TechLoginSideEffectsService
   * @param token
   * @param email
   */
  newPasswordDialog(token: string, email: string): void {
    this._modalControllerService.closeModal();
    this._modalControllerService.showModal(NewPasswordDialogComponent, '', {
      newPasswordData: {
        token,
        email
      }
    });
  }

  /**
   * @name launchAppByPassword
   * @description
   * dispatch an action login by password
   * @memberof TechLoginSideEffectsService
   * @param email
   * @param password
   */
  launchAppByPassword(email: string, password: string): void {
    this._store.dispatch(TechLoginActions.byPassword({login: email, password: password}));
  }

  /**
   * @name resetRequestPasswordAlert
   * @description
   * display an alert to info the user that the email have sent successfully
   * @memberof TechLoginSideEffectsService
   */
  resetRequestPasswordAlert(): void {
    this._alertControllerService.observableAlert({
      header: this._translationService.instant('LOGIN.RESET_PASSWORD.ALERT.HEADER'),
      subHeader: this._translationService.instant('LOGIN.RESET_PASSWORD.ALERT.SUB_HEADER'),
      message: this._translationService.instant('LOGIN.RESET_PASSWORD.ALERT.MESSAGE'),
      buttons: [
        {
          text: this._translationService.instant('LOGIN.RESET_PASSWORD.ALERT.BUTTONS.ACCEPT'),
          handler: () => {
            this._router.navigateByUrl("tech/login");
          }
        }
      ],
    })
  }
}
