import {Injectable} from "@angular/core";
import {BehaviorSubject} from "rxjs";
import {FormGroup} from "@angular/forms";
import {
  FormReportInterface
} from "@tech/pages/inventory/components/tech-inventory-mission-detail/services/interfaces/form-report.interface";

@Injectable({
  providedIn: 'root',
})
export class TechInventoryFormReportService {

  formReport$: BehaviorSubject<FormReportInterface>;

  constructor() {
    this.formReport$ = new BehaviorSubject<FormReportInterface>(<FormReportInterface>{
      form: new FormGroup<any>({}),
      formattedData: undefined,
      existingReportId: false
    });
  }

  resetFormReport() {
    this.formReport$.next(<FormReportInterface>{
      form: new FormGroup<any>({}),
      formattedData: undefined,
      existingReportId: false
    })
  }

}
