import { Injectable } from '@angular/core';
import { RequestOptions } from '@shared/interfaces/request-options.interface';
import { Observable } from 'rxjs';
import { OrgaResponse } from '@shared/interfaces/orga-response.interface';
import {
  FilterDto,
  MissionDistanceListDto,
  PointListDto,
} from '@server-models';
import { HttpClient } from '@angular/common/http';
import { TechHttpClientBaseApiService } from '@tech/services/http-client-base-api/tech-http-client-base-api.service';

@Injectable({
  providedIn: 'root',
})
export class TechInventoryMissionByDistanceApiService extends TechHttpClientBaseApiService {
  getMissionsByDistance(
    origin: PointListDto,
    filter: FilterDto[] = [],
    distance: number = 100000,
    pageNumber: number = 0,
    pageSize: number = 100
  ): Observable<OrgaResponse<MissionDistanceListDto[]>> {
    const options: RequestOptions = {
      params: {
        lat: origin?.lat?.toString() ?? '',
        lng: origin?.lng?.toString() ?? '',
        dist: distance.toString(),
        pageNumber: pageNumber.toString(),
        pageSize: pageSize.toString(),
      },
    };
    return this.post<OrgaResponse<MissionDistanceListDto[]>>(
      `/tech/mission/bydistance/filter`,
      true,
      filter,
      options
    );
  }
}
