import {Injectable} from "@angular/core";
import {OrgaResponse} from "@shared/interfaces/orga-response.interface";
import {PaginationParams} from "@shared/interfaces/pagination-params.interface";
import {
  FilterDto,
  IssueListDto,
  IssueTemplateListDto,
  Orders,
  ResourceListDto
} from "@server-models";
import {Observable} from "rxjs";
import {RequestOptions} from "@shared/interfaces/request-options.interface";
import {IssueBaseServiceInterface} from "@shared/components/issues/interfaces/issue-base-service.interface";
import {TemplatePreviewDtoType} from "@shared/components/issues/types/template-preview-dto.type";
import {IssueDtoType} from "@shared/components/issues/types/issue-dto.type";
import {SubmitIssueDtoType} from "@shared/components/issues/types/submit-issue-dto.type";
import {TechHttpClientBaseApiService} from "@tech/services/http-client-base-api/tech-http-client-base-api.service";

@Injectable({
  providedIn: 'root',
})
export abstract class TechIssuesApiService extends TechHttpClientBaseApiService implements IssueBaseServiceInterface {

  abstract getIssueById(issueId: number): Observable<IssueDtoType>;
  abstract getIssueTemplatePreviewListById(id: number): Observable<TemplatePreviewDtoType>;
  abstract sendIssueTemplatePreviewById(templateId: number, issue: SubmitIssueDtoType): Observable<void>;

  getIssueRecentList(params: PaginationParams): Observable<OrgaResponse<IssueListDto[]>> {
    const options = {
      params: {
        PageSize: params.pageSize.toString(),
        PageNumber: params.pageNumber.toString(),
        Cols: params.cols,
        SortField: "createdAt",
        Sort: Orders.Descending.toString()
      }
    }
    return super.get<OrgaResponse<IssueListDto[]>>(`/tech/issue/all`, undefined, options);
  }

  postIssueRecentFilterList(body: FilterDto[], options: RequestOptions): Observable<OrgaResponse<IssueListDto[]>> {
    return super.post<OrgaResponse<IssueListDto[]>>(`/tech/issue/all/filter`, undefined, body, options)
  }

  getIssueTemplateList(): Observable<OrgaResponse<IssueTemplateListDto[]>> {
    return super.get<OrgaResponse<IssueTemplateListDto[]>>(`/tech/issue/template`, undefined)
  }

  postIssueTemplateFilterList(body: FilterDto[]): Observable<OrgaResponse<IssueTemplateListDto[]>> {
    return super.post<OrgaResponse<IssueTemplateListDto[]>>(`/tech/issue/template/filter`, undefined, body)
  }

  requestResourceListByFilter(filter: FilterDto[]): Observable<OrgaResponse<ResourceListDto>> {
    return super.post<OrgaResponse<ResourceListDto>>(`/tech/resource/all/filter`, undefined, filter)
  }
}
