import {Component, OnInit} from '@angular/core';
import {CommonModule} from "@angular/common";
import {IonicModule} from "@ionic/angular";
import {select, Store} from "@ngrx/store";
import {TechInventorySelectors} from "@tech/pages/inventory/store/tech-inventory.selector-type";
import {Observable, of} from "rxjs";
import {
  CustomPropertySetsReadComponent
} from "@shared/components/custom-property/read/custom-property-sets-read.component";
import {HeaderDesignEnum} from "@shared/components/custom-property/read/enums/header-design.enum";
import {
  CustomPropertySetFormatted
} from "@shared/components/custom-property/interfaces/custom-property-set-formatted.interface";

@Component({
  selector: 'app-tech-inventory-mission-detail-custom-values-read',
  templateUrl: './custom-values-read.component.html',
  styleUrls: ['./custom-values-read.component.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule, CustomPropertySetsReadComponent],

})
export class TechInventoryMissionDetailViewCustomValuesReadComponent implements OnInit {
  protected readonly HeaderDesignEnum = HeaderDesignEnum;
  customPropertySetFormattedList$: Observable<CustomPropertySetFormatted[]>;

  constructor(
    private _store: Store
  ) {
    this.customPropertySetFormattedList$ = of();

  }

  ngOnInit() {
    this.customPropertySetFormattedList$ = this._store.pipe(select(TechInventorySelectors.selectTechInventoryPageMissionCustomPropertySetFormattedList));
  }

}
