import { Component, Input } from '@angular/core';
import { MissionDto } from '@server-models';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { ModalControllerService } from '@shared/services/modal-controller.service';
import { CapacitorBrowserService } from '@shared/services/capacitor/browser/capacitor-browser.service';

@Component({
  selector: 'app-tech-inventory-mission-shipping-address-modal',
  templateUrl: './mission-shipping-address-modal.component.html',
  styleUrls: ['./mission-shipping-address-modal.component.scss'],
  standalone: true,
  imports: [
    IonicModule,
    CommonModule,
    TranslateModule
  ],
  providers: [ModalControllerService, CapacitorBrowserService]
})
export class TechInventoryMissionShippingAddressModalComponent {
  @Input() missionDetail: MissionDto;

  constructor(
    private _modalService: ModalControllerService,
    private _cBrowser: CapacitorBrowserService
  ) {
    this.missionDetail = {};
  }

  onCloseModal(): void {
    this._modalService.closeModal();
  }

  onOpenGoogleMaps(): void {
    let query: string;
    const point = this.missionDetail.location?.shippingAddress?.point;
    const address = this.missionDetail.location?.shippingAddress;

    if (point?.lat && point.lat > 0 && point?.lng &&  point.lng > 0) {
      query = `${point.lng},${point.lat}`;
    } else {
      query = `${address?.street ?? ''} ${address?.houseNumber ?? ''}, ${address?.zipcode ?? ''}, ${address?.city ?? ''}, ${address?.countryCode ?? ''}`;
    }

    const url = 'https://maps.google.com/?q=' + encodeURIComponent(query);
    this._cBrowser.open(url);
  }
}
