import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {CommonModule, CurrencyPipe} from '@angular/common';
import {IonicModule} from '@ionic/angular';
import {TranslateModule} from '@ngx-translate/core';
import {InfoCardComponent} from '@team/pages/infos/components/info-card/info-card.component';
import {ButtonTeamSideMenuComponent} from '@team/components/button-team-side-menu/button-team-side-menu.component';
import {InputDropdownComponent} from '@shared/components/custom-property/inputs/dropdown/input-dropdown.component';
import {FormControl, FormGroup, FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ArticleUsageDto} from '@server-models';
import {
  TechInventoryCurrencyInputComponent
} from "@tech/pages/inventory/components/tech-inventory-mission-detail/pages/report-detail/components/currency-input/currency-input.component";
import {Subscription} from "rxjs";

export interface ReportArticleForm {
  articleId: number;
  localId: number;
  name: string;
  amount: number;
  unitPrice: number;
  totalPrice: number;
  note: string;
}

@Component({
  selector: 'app-tech-inventory-report-article',
  templateUrl: './report-article.component.html',
  styleUrls: ['./report-article.component.scss'],
  standalone: true,
  providers: [CurrencyPipe],
  imports: [
    IonicModule,
    CommonModule,
    TranslateModule,
    InfoCardComponent,
    ButtonTeamSideMenuComponent,
    TechInventoryCurrencyInputComponent,
    InputDropdownComponent,
    FormsModule,
    ReactiveFormsModule
  ]
})
export class TechInventoryReportArticleComponent implements OnInit, OnDestroy {

  @Input() article!: ArticleUsageDto;
  @Input() name: string = 'Small parts';
  @Input() amount: number = 0;
  @Input() unitPrice: number = 0;
  @Input() canDisplayNotes: boolean = false;
  @Input() note: string = '';
  @Input() amountLabel: string = 'Amount';
  @Input() amountUnitLabel: string = 'Pcs.';
  @Input() isRemovable: boolean = true;
  @Input() disableForm: boolean = false;

  @Output() articleChanged = new EventEmitter<ReportArticleForm>();
  @Output() remove = new EventEmitter<ReportArticleForm>();

  articleForm: FormGroup = new FormGroup({});
  articleFromValueChangesSubscription: Subscription | null;
  isShowNotes = false;
  isRemove = false;

  constructor() {
    this.articleFromValueChangesSubscription = null;
  }

  ngOnInit() {
    if (this.article) {
      this.name = this.article?.article?.name ?? '';
      this.unitPrice = this.article.price ?? 0;
      this.amountUnitLabel = this.article.unit ?? '';
      this.amount = this.article.count ?? 1;
    }

    this.articleForm = new FormGroup({
      articleId: new FormControl(this.article?.article?.articleId ?? 0),
      localId: new FormControl(this.article?.id ?? 0),
      name: new FormControl(this.name),
      amount: new FormControl(this.amount),
      unitPrice: new FormControl(this.unitPrice),
      totalPrice: new FormControl(0),
      note: new FormControl(this.note)
    });


    // Triggers articleChanged
    this.articleFromValueChangesSubscription = this.articleForm.valueChanges.subscribe((value: ReportArticleForm) => this.articleChanged.emit(value));

    // Trigger first changes
    this.isShowNotes = !!this.note;
    this.onPriceChanged();
  }

  onToggleNotes() {
    this.isShowNotes = !this.isShowNotes;
  }

  onRemove(): void {
    if (this.isRemovable) {
      this.remove.emit(this.articleForm.value);
    }
  }

  onPriceChanged(): void {
    this.articleForm.get('totalPrice')?.setValue(this.articleForm.value['amount'] * this.articleForm.value['unitPrice']);
  }

  createOptionNumbers(start: number, end: number, step: number = 1): number[] {
    const options = [];
    for (let i = start; i < end + step; i += step) {
      options.push(i);
    }
    return options;
  }

  ngOnDestroy(): void {
    if (this.articleFromValueChangesSubscription) {
      this.articleFromValueChangesSubscription.unsubscribe();
    }
  }
}
