import {Component, OnInit} from '@angular/core';
import {
  ArticleUsageDto,
  CustomPropertyDto,
  CustomSetReferenceDto,
  FilledCustomValueDto,
  MissionDto,
  MissionReportDto,
  MissionState
} from "@server-models";
import {CommonModule} from "@angular/common";
import {IonicModule} from "@ionic/angular";
import {TranslateModule, TranslateService} from "@ngx-translate/core";
import {select, Store} from "@ngrx/store";
import {ButtonTechSideMenuComponent} from "@tech/components/button-tech-side-menu/button-tech-side-menu.component";
import {ActivatedRoute, Router, RouterOutlet} from "@angular/router";
import {TechInventorySelectors} from "@tech/pages/inventory/store/tech-inventory.selector-type";
import {Observable, of, switchMap, take} from "rxjs";
import {TechInventoryActions} from "@tech/pages/inventory/store/tech-iventory.actions-type";
import {AssignedUserEnum} from "@tech/pages/inventory/enums/segment-settings.emun";
import {FormGroup, FormsModule} from "@angular/forms";
import {map} from "rxjs/operators";
import {
  TechInventoryMissionPagesService
} from "@tech/pages/inventory/components/tech-inventory-mission-detail/services/mission-pages.service";
import {
  TechInventoryFormReportService
} from "@tech/pages/inventory/components/tech-inventory-mission-detail/services/form-report.service";
import {StereotypeBaseService} from "@shared/services/stereotype/stereotype-base.service";
import {
  FormReportInterface
} from "@tech/pages/inventory/components/tech-inventory-mission-detail/services/interfaces/form-report.interface";

@Component({
  selector: 'app-tech-inventory-mission-detail',
  templateUrl: './tech-inventory-mission-detail.component.html',
  styleUrls: ['./tech-inventory-mission-detail.component.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule, TranslateModule, ButtonTechSideMenuComponent, RouterOutlet, FormsModule],

})
export class TechInventoryMissionDetailComponent implements OnInit {
  protected readonly MissionState = MissionState;
  missionDetail$: Observable<MissionDto | undefined>;
  missionId$: Observable<string | null>;
  isLoading$: Observable<boolean>;
  allowReportSubmit: boolean;
  submitNeeds: FormReportInterface;

  protected readonly AssignedUserEnum = AssignedUserEnum;

  constructor(
    public formReportService: TechInventoryFormReportService,
    private _store: Store,
    private _translateService: TranslateService,
    public missionPagesService: TechInventoryMissionPagesService,
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _stereotypeBaseService: StereotypeBaseService
  ) {
    this.missionDetail$ = of();
    this.allowReportSubmit = false;
    this.missionId$ = this._activatedRoute.paramMap.pipe(
      take(1),
      map((params) => {
        return params.get('id')
      }),
      switchMap((id) => {
        return id ? of(id) : of(null)
      })
    );
    this.isLoading$ = of();
    this.submitNeeds = <FormReportInterface>{};
  }

  ngOnInit() {
    this.missionDetail$ = this._store.pipe(select(TechInventorySelectors.selectTechInventoryPageMissionDetail));
    this.isLoading$ = this._store.pipe(select(TechInventorySelectors.selectTechInventoryPageMissionLoading));

    this.formReportService.formReport$.subscribe((value) => {
      this.submitNeeds = value;
    });

    this.missionId$.pipe(take(1)).subscribe((missionId) => {
      if (missionId) {
        const urlSegments = this._router.url.split('/');
        // Find the index of the missionId
        const idIndex = urlSegments.findIndex(segment => segment === missionId);

        if (idIndex !== -1) {
          const existingUrl = urlSegments.slice(idIndex + 1);
          this._store.dispatch(TechInventoryActions.getMissionDetailById({
            missionId: missionId,
            reportDemand: false,
            existingUrl
          }));

        }
      }
    });
  }

  formatTitle(id: number): string {
    return `${this._translateService.instant('COMPONENTS.MISSIONS.TITLE_ONE')} #${id ? id : ''}`;
  }

  back() {
    this.missionPagesService.resetSegment();
    this._store.dispatch(TechInventoryActions.getMissionReportPreviewByIdReset());
    this._store.dispatch(TechInventoryActions.navigateToInventory());
  }

  checkReportRoute() {
    return this._router.url.endsWith('/report');
  }

  submitReport() {
    if (this.submitNeeds) {
      let preparedUsedArticles: ArticleUsageDto[];
      let mutateCustomPropertyValues: FilledCustomValueDto[] = [];
      const customPropertySet: CustomSetReferenceDto[] = this.submitNeeds.customPropertySets;
      if (customPropertySet.length > 0) {
        const customValuesFormGroup = this.submitNeeds.form.get('CustomValues') as FormGroup;
        if (customValuesFormGroup) {
          const customValuesControls = Object.keys(customValuesFormGroup.controls);
          if (customValuesControls.length > 0) {
            const customPropertyValues = this._stereotypeBaseService.prepareCustomValues(customValuesFormGroup); // to build the customValues
            const sortedCustomPropertySets: CustomSetReferenceDto[] = this._stereotypeBaseService.sortCustomPropertySets(customPropertySet);
            const stereotypeCustomProperties: CustomPropertyDto[] = this._stereotypeBaseService.flattenCustomProperties(sortedCustomPropertySets);
            mutateCustomPropertyValues = this._stereotypeBaseService.prepareCustomPropertyValues(customPropertyValues, this.submitNeeds.reportType.tenantId, stereotypeCustomProperties);
          }
        }
      } else {
        mutateCustomPropertyValues = [];
      }

      if (this.submitNeeds.usedArticles.length > 0) {
        preparedUsedArticles = this._removeIdProperty(this.submitNeeds.usedArticles)
      } else {
        preparedUsedArticles = [];
      }

      const reportToSend: MissionReportDto = {
        missionId: this.submitNeeds.missionId!,
        total: this.submitNeeds.totalCosts,
        tenantId: this.submitNeeds.tenantId,
        stereotypeId: this.submitNeeds.reportType.stereotypeId,
        stereotypeRowVersion: this.submitNeeds.reportType.rowVersion,
        travelDurationInHours: this.submitNeeds.travelDurationInHours,
        travelDurationCost: this.submitNeeds.travelDurationCost,
        workDurationInHours: this.submitNeeds.workDurationInHours,
        workDurationCost: this.submitNeeds.workDurationCost,
        customPropertyValues: mutateCustomPropertyValues,
        usedArticles: preparedUsedArticles,
      };

      // when there is not existing report, we don't have reportId
      if (this.submitNeeds.reportId != null && !isNaN(this.submitNeeds.reportId)) {
        reportToSend.missionReportId = this.submitNeeds.reportId;
      }

      this._store.dispatch(TechInventoryActions.submitMissionReport({
        missionReport: reportToSend,
        formattedData: this.submitNeeds.formattedData,
        existingReportId: this.submitNeeds.existingReportId
      }));
    }
  }

  /**
   * @name _removeIdProperty
   * @description
   * receive an array of ArticleUsageDto and split the object in id property and rest properties
   * and return the rest properties removing id from the object
   * @memberof TechInventoryMissionDetailComponent
   * @param articleUsageDtoList
   * @private
   */
  private _removeIdProperty(articleUsageDtoList: ArticleUsageDto[]): Omit<ArticleUsageDto, 'id'>[] {
    return articleUsageDtoList.map(({id, ...restObject}) => restObject);
  }

}
