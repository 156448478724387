import {mapToCanActivate, Route, RouterStateSnapshot} from "@angular/router";
import {
  KnowledgeDetailStartComponent
} from "@tech/pages/knowledge/components/knowledge-detail-start/knowledge-detail-start.component";
import {knowledgeDetailResolver} from "@tech/pages/knowledge/store/knowledge-detail.resolver";
import {DocumentsPage} from "@tech/pages/documents/documents.page";
import {inject} from "@angular/core";
import {AppType} from "@shared/models/AppType";
import {ResetPasswordComponent} from "@tech/pages/reset-password/reset-password.component";
import {BaseIssueReadComponent} from "@shared/components/issues/components/base-issue-read/base-issue-read.component";
import {
  BaseIssuesTemplatePreviewComponent
} from "@shared/components/issues/components/base-issue-template-preview/base-issues-template-preview.component";
import {
  BaseIssueSuccessComponent
} from "@shared/components/issues/components/base-issue-success/base-issue-success.component";
import {ArticlesPage} from "@tech/pages/knowledge/components/articles/articles.page";
import {articlesPageResolver} from "@tech/pages/knowledge/store/articles-page.resolver";
import {
  BaseIssueTemplateComponent
} from "@shared/components/issues/components/base-issue-template/base-issue-template.component";
import {TechPage} from "@tech/pages/tech/tech-page.component";
import {TechAppLoginGuard} from "@tech/guard/app-login.guard";
import {techLoginChangePasswordResolver} from "@tech/pages/login/store/tech-login-change-password.resolver";
import {TechLoginChangePasswordGuard} from "@tech/pages/change-password/guards/login-change-password.guard";
import {TechChangePasswordComponent} from "@tech/pages/change-password/change-password.component";
import {TechLoginPage} from "@tech/pages/login/login.page";
import {IssuesPage} from "@tech/pages/issues/issues.page";
import {TechIssueReadGuard} from "@tech/pages/issues/components/issue-read/guards/tech-issue-read.guards";
import {TechLoginPageGuard} from "@tech/pages/login/guards/tech-login-page-guard.service";
import {InfosPage} from "@tech/pages/infos/infos.page";
import {KnowledgePage} from "@tech/pages/knowledge/knowledge.page";
import {InventoryPage} from "@tech/pages/inventory/inventory.page";
import {
  TechInventoryMissionDetailComponent
} from "@tech/pages/inventory/components/tech-inventory-mission-detail/tech-inventory-mission-detail.component";
import {
  TechInventoryMissionDetailPage
} from "@tech/pages/inventory/components/tech-inventory-mission-detail/pages/mission-detail-view/mission-detail.page";
import {
  TechInventoryReportDetailPage
} from "@tech/pages/inventory/components/tech-inventory-mission-detail/pages/report-detail/report-detail-page.component";
import {
  ReportSuccessComponent
} from "@tech/pages/inventory/components/tech-inventory-mission-detail/pages/report-detail/components/report-success/report-success.component";
import {
  TechInventoryIssueDetailPage
} from "@tech/pages/inventory/components/tech-inventory-mission-detail/pages/issue-detail/issue-detail.page";

export default [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'tech',
  },
  {
    path: 'tech',
    children: [
      {
        path: '',
        redirectTo: 'login',
        pathMath: 'full'
      },
      {
        path: 'login',
        component: TechLoginPage,
        canActivate: mapToCanActivate([TechLoginPageGuard])
      },
      {
        path: 'login/reset-password',
        component: ResetPasswordComponent
      },
      {
        path: 'login/change-password',
        component: TechChangePasswordComponent,
        resolve: {loginData: techLoginChangePasswordResolver},
        canActivate: mapToCanActivate([TechLoginChangePasswordGuard])
      },
      {
        path: 'logged',
        component: TechPage,
        canActivate: [
          (_: any, state: RouterStateSnapshot) => inject(TechAppLoginGuard).canActivate((AppType.Tech), state)
        ],
        children: [
          {
            path: '',
            redirectTo: 'infos',
          },
          {
            path: 'infos',
            component: InfosPage,
          },
          {
            path: 'inventory',
            component: InventoryPage,
          },
          {
            path: 'inventory/mission/:id',
            component: TechInventoryMissionDetailComponent,
            children: [
              {
                path: 'view',
                component: TechInventoryMissionDetailPage
              },
              {
                path: 'report',
                component: TechInventoryReportDetailPage
              },
              {
                path: 'report/success',
                component: ReportSuccessComponent
              },
              {
                path: 'issue',
                component: TechInventoryIssueDetailPage
              },

            ]
          },
          {
            path: 'knowledge',
            component: KnowledgePage,
          },
          {
            path: 'knowledge/:id',
            component: ArticlesPage,
            resolve: {article: articlesPageResolver}
          },
          {
            path: 'knowledge/:id/start',
            component: KnowledgeDetailStartComponent,
            resolve: {detail: knowledgeDetailResolver},
          },
          {
            path: 'documents',
            component: DocumentsPage,
          },
          {
            path: 'documents/:path',
            component: DocumentsPage,
            pathMatch: 'full',
          },
          {
            path: 'issues',
            component: IssuesPage,
          },
          {
            path: 'issues/new',
            component: BaseIssueTemplateComponent
          },
          {
            path: 'issues/new/:id',
            component: BaseIssuesTemplatePreviewComponent,
            pathMatch: 'full',
          },
          {
            path: 'issues/success',
            component: BaseIssueSuccessComponent,
            pathMatch: 'full'
          },
          {
            path: 'issues/:id',
            component: BaseIssueReadComponent,
            canActivate: mapToCanActivate([TechIssueReadGuard]),
            pathMatch: 'full',
          },
        ]
      },

    ],
  },
] as Route[];

