<ion-spinner *ngIf="(issueFormattedDataIsLoading$ | async) else main"></ion-spinner>
<ng-template #main>
  <ion-content class="main">
    <ion-card>
      <ion-card-content>
        <app-custom-read-property-sets
          *ngIf="(issueFormattedData$ | async) as customPropertyStructureFormatted"
          [propertySetsDetail]="customPropertyStructureFormatted.customPropertySets"
          [headerDesign]="HeaderDesignEnum.Title"
        ></app-custom-read-property-sets>
      </ion-card-content>
    </ion-card>
  </ion-content>
</ng-template>

