<ion-card class="settings">
  <ion-select
    [placeholder]="'COMPONENTS.MISSIONS.RESOURCE.PLACEHOLDER' | translate"
    interface="popover"
    [(ngModel)]="currentSelectedObject.stereotype"
    (ionChange)="changeStereotypeMode($event)"
    [ngClass]="{'is-selected': isStereotypeSelected}">
    <ion-select-option *ngIf="stereotypes.length > 0" [value]="''">
      {{ 'COMPONENTS.MISSIONS.RESOURCE.ALL' | translate }}
    </ion-select-option>
    <ion-select-option *ngFor="let stereotype of stereotypes" [value]="stereotype.stereotypeId">
      {{ stereotype.name }}
    </ion-select-option>
  </ion-select>

  <ion-select
    [placeholder]="'COMPONENTS.MISSIONS.STATE.PLACEHOLDER' | translate"
    interface="popover"
    [(ngModel)]="currentSelectedObject.state"
    (ionChange)="changeStatusMode($event)"
    [ngClass]="{'is-selected': currentSelectedObject.state !== null}">
    <ion-select-option [value]="''">
      {{ 'COMPONENTS.MISSIONS.STATE.ALL' | translate }}
    </ion-select-option>
    <ion-select-option [value]="MissionState.Instructed">
      {{ 'COMPONENTS.MISSIONS.STATE.INSTRUCTED' | translate }}
    </ion-select-option>
    <ion-select-option [value]="MissionState.Created">
      {{ 'COMPONENTS.MISSIONS.STATE.CREATED' | translate }}
    </ion-select-option>
  </ion-select>

  <ion-select
    aria-label="Ansicht"
    placeholder="View"
    interface="popover"
    value="list"
    (ionChange)="missionDisplaySettingsChanged.emit($event.detail.value)"
    class="is-selected">
    <ion-select-option value="list">{{ 'COMPONENTS.MISSIONS.VIEW.LIST' | translate }}</ion-select-option>
    <ion-select-option value="map"> {{ 'COMPONENTS.MISSIONS.VIEW.MAP' | translate }}</ion-select-option>
  </ion-select>

  <div class="icon-container" (click)="clearSettingsFilters()">
    <ion-icon size="large" name="close-outline"></ion-icon>
  </div>

</ion-card>
