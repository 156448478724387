import { Component, OnInit } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { Storage } from '@ionic/storage-angular';
import { Store } from '@ngrx/store';
import { appInit } from '@core/store/app.actions';
import { environment } from '@env-tech/environment';
import { LanguageService } from '@shared/services/language.service';
import { Loader, Libraries } from '@googlemaps/js-api-loader';
import { forkJoin, from, Observable, take } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ToastControllerService } from '@shared/services/toast-controller.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: 'tech-app.component.html',
  styleUrls: ['tech-app.component.scss'],
  standalone: true,
  imports: [IonicModule]
})
export class TechAppComponent implements OnInit {

  public googleMapsApiLoader: Loader;

  constructor(
    private _storage: Storage,
    private _store: Store,
    private _languageService: LanguageService,
    private _toastService: ToastControllerService,
    private _translate: TranslateService
  ) {
    // Get default language
    this._languageService.setDefaultLanguageByEnvironment(environment);
    // Get google maps api
    this.googleMapsApiLoader = new Loader({
      apiKey: 'AIzaSyBINOesU6mmaz46mq5tDVKlfUBCfKXnEnA',
      version: 'weekly'
    });
    this._loadGoogleMapsAPI();
  }

  async ngOnInit() {
    this._store.dispatch(appInit());
    await this._storage.create();
  }

  private _loadGoogleMapsAPI(): void {
    // Init observable with library calls
    const loader$: Observable<any> = forkJoin({
      core: this.googleMapsApiLoader.importLibrary('core'),
      maps: this.googleMapsApiLoader.importLibrary('maps'),
      marker: this.googleMapsApiLoader.importLibrary('marker')
    });
    // Load or catch error
    loader$.pipe(
      take(1),
      catchError(() => this._toastService.observableToast(
        this._translate.instant('COMPONENTS.MISSIONS.MAP.TOASTS.NO_GOOGLE_MAPS_API')
      ))
    ).subscribe();
  }
}
